import React from 'react';
import {
  Button,
  ModalBase,
  Card,
  token,
  Tooltip,
  Form,
  Input,
  Divider,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { SectionTemplatesEnum } from '../../constants/sections';
import styled from 'styled-components';
import whiteTemplate from '../../assets/whiteTemplate.png';
import homePageTemplate from '../../assets/homePageTemplate.svg';
import { useLandingPageMutations } from '../../mutations/landingPage';
import { useInspectorStore } from '../../stores/inspectorStore';
import { GUIDE_TITLE_MAX_LENGTH } from '../../constants/guides';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';

export interface CreateModalProps {
  onCancel: () => void;
}

const PageTemplateModal: React.FC<CreateModalProps> = ({ onCancel }) => {
  const { translate } = useTranslate();
  const { createLandingPage, addPageTemplate } = useLandingPageMutations();
  const activeSectionTemplate = useInspectorStore(s => s.activeSectionTemplate);
  const setSectionTemplate = useInspectorStore(s => s.setSectionTemplate);
  const [title, setTitle] = React.useState('');

  const handleAddSectionTemplate = () => {
    if (activeSectionTemplate === SectionTemplatesEnum.WHITE_TEMPLATE) {
      createLandingPage.mutate({ guideTitle: title });
      return;
    }

    addPageTemplate.mutate({
      pageTitle: title,
      pageTemplate: activeSectionTemplate,
    });
  };

  const PageTemplateCard = ({
    sectionTemplate,
    templateImg,
  }: {
    sectionTemplate: SectionTemplatesEnum;
    templateImg: string;
  }) => (
    <Card
      variant="clean"
      onClick={() => setSectionTemplate(sectionTemplate)}
      onSelect={() => setSectionTemplate(sectionTemplate)}
      isSelected={activeSectionTemplate === sectionTemplate}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <Card.Img src={templateImg} />
        </Card.Images>
      </Card.Frame>
    </Card>
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header title="New Landing page" />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="modalInput">Title</Form.Label>
          <Input
            id="modalInput"
            placeholder="Enter a title"
            value={title}
            onChange={setTitle}
            data-testid="modalInput"
            maxLength={GUIDE_TITLE_MAX_LENGTH}
            iconRight={
              <MaxCharacterCounter
                value={title}
                maxValue={GUIDE_TITLE_MAX_LENGTH}
              />
            }
          />
        </Form.Group>
        <Divider />
        <ModalContentTile>Select Template</ModalContentTile>
        <Grid>
          <Tooltip content={translate('WIDGET_MENU.ADD_SECTION_BOTTOM')}>
            <PageTemplateCard
              sectionTemplate={SectionTemplatesEnum.WHITE_TEMPLATE}
              templateImg={whiteTemplate}
            />
          </Tooltip>

          <PageTemplateCard
            sectionTemplate={SectionTemplatesEnum.LANDING_PAGE_TEMPLATE}
            templateImg={homePageTemplate}
          />
        </Grid>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            onClick={handleAddSectionTemplate}
            isDisabled={!title}
          >
            <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default PageTemplateModal;

const ModalContentTile = styled.div`
  margin-top: ${token.spacing6};
`;

const Grid = styled.div`
  margin-top: ${token.spacing6};
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: ${token.spacing6};
  align-items: flex-start;
`;
