import { RouterHistory } from '@sentry/react/types/reactrouter';
import { LOCAL_STORAGE_KEYS } from '../constants/guides';
import { OrderBy, OrderType, OverviewOrder } from '../types';
import { useAppStoreBase } from '../stores/appStore';

const REDIRECT_PATH = 'redirectPath';

const beforeUnloadPrompt = (event: Event) => {
  event.preventDefault();
  event.returnValue = true;
};

const defaultOrder: OverviewOrder = {
  orderBy: OrderBy.TITLE,
  orderType: OrderType.ASC,
  searchTerm: '',
};

export function listenBeforeUnload() {
  window.addEventListener('beforeunload', beforeUnloadPrompt);
}

export function unlistenBeforeUnload() {
  window.removeEventListener('beforeunload', beforeUnloadPrompt);
}

export function getCookie(name: string) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

  return match ? match[2] : '';
}

export function redirectToLogin() {
  const redirectToken = getCookie('redirectToken');

  window.location.replace(`/login/redirectToken/${redirectToken}/`);
}

export function setRedirectPathInLocalStorage(
  redirectPath: string,
  redirectHash: string,
) {
  if (redirectPath !== '/') {
    localStorage.setItem(
      REDIRECT_PATH,
      JSON.stringify([redirectPath, redirectHash]),
    );
  }
}

export function clearRedirectPathFromLocalStorage() {
  localStorage.removeItem(REDIRECT_PATH);
}

const getAndClearRedirectPathFromLocalStorage = (): string[] => {
  const redirectPath = localStorage.getItem(REDIRECT_PATH);

  if (redirectPath) {
    clearRedirectPathFromLocalStorage();
  }

  return JSON.parse(redirectPath);
};

export function detectAndHandleRedirectFromLogin(history: RouterHistory) {
  const redirectPath = getAndClearRedirectPathFromLocalStorage();
  const referrer = document.referrer;

  // Referer existing means user got redirected (possibly from login/SSO)
  // redirectPath existing means the user got previously redirected to login
  if (referrer && redirectPath) {
    history.push({
      pathname: redirectPath[0],
      hash: redirectPath[1],
    });
  }
}

export function getOrderByFromLocalStorage() {
  return (
    (localStorage.getItem(LOCAL_STORAGE_KEYS.OVERVIEW_ORDER_BY) as OrderBy) ||
    defaultOrder.orderBy
  );
}

export function getOrderTypeFromLocalStorage() {
  return (
    (localStorage.getItem(
      LOCAL_STORAGE_KEYS.OVERVIEW_ORDER_TYPE,
    ) as OrderType) || defaultOrder.orderType
  );
}

window.addEventListener('offline', () =>
  useAppStoreBase.getState().setisOffline(true),
);
window.addEventListener('online', () =>
  useAppStoreBase.getState().setisOffline(false),
);
