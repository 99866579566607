import { create } from 'zustand';
import { createSelectors } from './helpers';

interface UploadingImage {
  id: string;
}

interface UploaderStore {
  uploadingImages: UploadingImage[];
  setUploadingImage: (uploadingImage: UploadingImage) => void;
  clearUploadingImage: (widgetId: string) => void;
}

export const useUploaderStoreBase = create<UploaderStore>(set => ({
  uploadingImages: [],
  setUploadingImage: (uploadingImage: UploadingImage) =>
    set(state => ({
      uploadingImages: [...state.uploadingImages, uploadingImage],
    })),

  clearUploadingImage: (id: string) =>
    set(state => ({
      uploadingImages: state.uploadingImages.filter(image => image.id !== id),
    })),
}));

export const useUploaderStore = createSelectors(useUploaderStoreBase);
