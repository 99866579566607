import styled, { createGlobalStyle } from 'styled-components';
import { token } from '@bynder/design-system';
import { scrollShadow, TEXT, VISUALS } from '../../../constants/styles';

interface ModalProps {
  width: string;
  height: string;
  minHeight: string;
}

type ModalContainerProps = {
  maxHeight: string;
  height?: string;
};

export const NoScrollBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: ${VISUALS.ZINDEX_MODAL_WRAPPER};
  margin: 0 ${token.spacing5};
  pointer-events: none;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const StyledModalContainer = styled.div<ModalContainerProps>`
  position: relative;
  display: flex;
  max-height: ${props => props.maxHeight || `calc(100vh - 96px)`};
  flex-wrap: nowrap;
  height: ${(props: ModalContainerProps) => props.height || `auto`};
`;

export const StyledModal = styled.div<ModalProps>`
  z-index: ${VISUALS.ZINDEX_MODAL};
  display: flex;
  width: ${props => props.width || `520px`};
  height: ${props => props.height || `auto`};
  min-height: ${props => props.minHeight || 'unset'};
  flex-direction: column;
  pointer-events: all;
  border-radius: ${token.radiusLarge};
  background: ${token.white};
  box-shadow:
    0 0 0 1px ${token.gray100a},
    0 3px 12px ${token.gray200a};

  h1,
  h2 {
    font-size: ${TEXT.ALPHA_TEXT_SIZE};
    font-weight: normal;
    line-height: normal;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  padding: 0 ${token.spacing5};
  flex-shrink: 0;

  > h1 {
    margin-right: ${token.spacing8};
  }
`;

type ModalContentProps = {
  isOverflowVisible?: boolean;
};

export const StyledModalContent = styled.div<ModalContentProps>`
  padding: ${token.spacing5} ${token.spacing6};
  flex-grow: 1;

  overflow: ${props =>
    props.isOverflowVisible ? 'visible' : `hidden; overflow-y: auto;`};

  ${scrollShadow}

  b {
    font-weight: 600;
  }

  hr {
    border-color: ${token.white};
    border-style: solid;
    margin-block-start: 14px;
    margin-block-end: 14px;
  }
`;

export const StyledModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-right: ${token.spacing5};

    &:last-child {
      margin-right: 0;
    }
  }

  padding: ${token.spacing6};
  flex-shrink: 0;
  flex-wrap: wrap;
`;

export const StyledModalDescription = styled.span`
  font-size: ${token.fontSize75};
  line-height: 20px;
  color: ${token.gray500a};
`;

export const StyledGuideStateInfoLabel = styled.p`
  font-size: ${token.fontSize100};
`;

export const StyledGuideStateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
