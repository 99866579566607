import { debounce } from 'lodash';

import { AnalyticsEventProperties } from '../types';
import { isDevelopment } from '../constants/app';

export const TEXT_INPUT_DEBOUNCE_MS = 3000;
const PRODUCT_CATEGORY = 'Brand Guidelines';

const sendTextInputFieldAnalytics = debounce(
  (
    eventName: string,
    eventProperties: AnalyticsEventProperties,
    previousValueLength: number,
    newValueLength: number,
  ) => {
    if (trackTextInputFieldWithDebounce.isInputValueEmpty) {
      window.bynder.track(eventName + ' Created', {
        'Text length': newValueLength,
        ...eventProperties,
      });
      trackTextInputFieldWithDebounce.isInputValueEmpty = false;
    } else if (previousValueLength > 0 && newValueLength > 0) {
      window.bynder.track(eventName + ' Edited', {
        'Text length': newValueLength,
        ...eventProperties,
      });
    } else if (previousValueLength > 0 && newValueLength === 0) {
      window.bynder.track(eventName + ' Removed', {
        ...eventProperties,
      });
      trackTextInputFieldWithDebounce.isInputValueEmpty = true;
    }
  },
  TEXT_INPUT_DEBOUNCE_MS,
);

export const trackTextInputFieldWithDebounce = (
  eventName: string,
  eventProperties: AnalyticsEventProperties,
  previousValueLength: number,
  newValueLength: number,
) => {
  if (
    !trackTextInputFieldWithDebounce.isInputValueEmpty &&
    previousValueLength === 0
  ) {
    trackTextInputFieldWithDebounce.isInputValueEmpty = true;
  }

  sendTextInputFieldAnalytics(
    eventName,
    eventProperties,
    previousValueLength,
    newValueLength,
  );
};
trackTextInputFieldWithDebounce.isInputValueEmpty = false;

export const track = (
  eventName: string,
  eventProperties?: AnalyticsEventProperties,
) => {
  if (window?.bynder?.track) {
    window.bynder.track(eventName, {
      ...eventProperties,
      product_category: PRODUCT_CATEGORY,
    });
  }
};

export const mockedAnalytics = {
  track: (eventName: string, eventProperties: AnalyticsEventProperties) => {
    // Log events to console during development
    if (isDevelopment) {
      console.log('Analytics: ' + eventName, eventProperties);
    }
  },

  push: () => {},
};
