export enum GUIDE_VIEW_MODE {
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW',
  PUBLISHED = 'PUBLISHED',
  PUBLIC = 'PUBLIC',
}

export const GUIDE_TITLE_MAX_LENGTH = 255;
export const MAX_GUIDE_PERCENTAGE_BEFORE_NOTIFICATION = 95;

export enum GuideOverviewLayout {
  GRID = 'grid',
  LIST = 'list',
}

export const LOCAL_STORAGE_KEYS = {
  OVERVIEW_LAYOUT: 'guideOverviewLayout',
  OVERVIEW_ORDER_BY: 'guideOverviewOrderBy',
  OVERVIEW_ORDER_TYPE: 'guideOverviewOrderType',
};

export interface ChangingEntity {
  correlationId: string;
  entityId: string;
  message: string;
  queryPath: string;
  payload: object;
}

export enum InspectorSetting {
  GUIDE_VIEW = 'GUIDE_VIEW',
  PAGE_VIEW = 'PAGE_VIEW',
  SECTION_VIEW = 'SECTION_VIEW',
  WIDGET_VIEW = 'WIDGET_VIEW',
}
