/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import React, { Suspense, useContext } from 'react';
import { ROUTES } from './constants/app';
import { AbilityContext } from './context/ability';
import { track } from './helpers/analytics';
import { detectAndHandleRedirectFromLogin } from './helpers/browser';
import { useUserType, UserType } from './helpers/hooks';
import {
  CONTENT_CUSTOMIZATION_PERMISSIONS,
  PERMISSION_ENTITIES,
  GUIDELINES_PERMISSIONS,
} from './helpers/permissions';
import { Props as GroupOverviewProps } from './components/overview/Groups/GroupOverview';
import { Props as ThemesetProps } from './components/themesets/Themeset';
import { GAtrackNavigation } from './helpers/googleAnalytics';
import { useGuideStore } from './stores/guideStore';
import { useReactQueryWebSocketsManager } from './hooks/useReactQueryWebSocketsManager';
import { OverviewSections, useOverviewStore } from './stores/overviewStore';
import { ActiveSplits, useReleaseToggle } from './helpers/releaseToggle';

export interface MatchParams {
  guideId?: string;
  pageId?: string;
}

const Guide = React.lazy(() => import('./components/guides/Guide'));
const GuidePageNotFound = React.lazy(
  () => import('./components/errorPages/GuidePageNotFound'),
);
const GroupOverview = React.lazy(
  () => import('./components/overview/Groups/GroupOverview'),
);

const Overview = React.lazy(() => import('./components/overview/Overview'));

const OverviewV2 = React.lazy(
  () => import('./components/overview/v2/Overview/OverviewV2'),
);

const ThemesetsOverview = React.lazy(
  () => import('./components/overview/Themesets/ThemesetsOverview'),
);
const Search = React.lazy(() => import('./components/search/Search'));

const RedirectToLogin = React.lazy(
  () => import('./components/shared/RedirectToLogin'),
);
const MajesticFontEditor = React.lazy(
  () => import('./components/themesets/MajesticFontEditor'),
);
const Themeset = React.lazy(() => import('./components/themesets/Themeset'));

const appCuesOnNewPage = () => {
  if (window.Appcues) {
    window.Appcues.page();
  }
};

const Router: React.FC = () => {
  useReactQueryWebSocketsManager();
  const history = useHistory();
  const setActiveGuideId = useGuideStore(s => s.setActiveGuideId);
  const setActivePageId = useGuideStore(s => s.setActivePageId);
  const setIsEditMode = useGuideStore(s => s.setIsEditMode);
  const setActiveOverviewSection = useOverviewStore.use.setActiveSection();
  const { data: isLandingPageEnabled } = useReleaseToggle(
    ActiveSplits.landingPage,
  );

  const abilities = useContext(AbilityContext);

  const { userType, isLoading } = useUserType();

  const canContentManage = abilities.can(
    CONTENT_CUSTOMIZATION_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.CONTENT_CUSTOMIZATION_SETTINGS,
  );
  const canEditThemesets = abilities.can(
    GUIDELINES_PERMISSIONS.CREATE,
    PERMISSION_ENTITIES.THEMESETS,
  );

  React.useEffect(() => {
    if (
      (userType === UserType.LOGGED_IN ||
        userType === UserType.OPEN_ASSET_BANK) &&
      !isLoading
    ) {
      detectAndHandleRedirectFromLogin(history);
    }
  }, [userType, isLoading, history]);

  const displayContentSettingsIfContentManage = () =>
    canContentManage ? <MajesticFontEditor /> : null;

  const displayThemesetsOverviewIfAllowed = () =>
    canEditThemesets ? <ThemesetsOverview /> : null;

  const displayThemesetIfAllowed = (props?: ThemesetProps) =>
    canEditThemesets ? <Themeset {...props} /> : null;

  const handleLoggedOut = (component: any) =>
    userType === UserType.LOGGED_IN || userType === UserType.OPEN_ASSET_BANK ? (
      component
    ) : (
      <RedirectToLogin />
    );

  const redirectAndTrack = (overviewSection: OverviewSections) => {
    GAtrackNavigation();
    appCuesOnNewPage();
    setIsEditMode(false);
    if (isLandingPageEnabled) {
      setActiveOverviewSection(overviewSection);
      return handleLoggedOut(<OverviewV2 />);
    }

    return handleLoggedOut(<Overview />);
  };

  useHandleOldHashedRoutes();

  if (isLoading || userType === UserType.UNKNOWN) return null;

  return (
    <Suspense>
      <Switch>
        <Route
          exact
          path={ROUTES.GROUP_OVERVIEW(':groupId')}
          children={(props: GroupOverviewProps) => {
            GAtrackNavigation();
            appCuesOnNewPage();
            setIsEditMode(false);
            if (isLandingPageEnabled) {
              setActiveOverviewSection(OverviewSections.GROUP_DETAILS_OVERVIEW);
              return handleLoggedOut(<OverviewV2 />);
            }

            return handleLoggedOut(<GroupOverview {...props} />);
          }}
        />
        <Route
          exact
          path={ROUTES.GUIDE_EDIT(':guideId')}
          children={(props: RouteComponentProps<MatchParams>) => {
            appCuesOnNewPage();
            setActiveGuideId(props.match.params.guideId);
            setIsEditMode(true);
            return handleLoggedOut(<Guide />);
          }}
        />
        <Route
          exact
          path={ROUTES.GUIDE(':guideId')}
          children={(props: RouteComponentProps<MatchParams>) => {
            appCuesOnNewPage();
            track('Guide Opened');
            setActiveGuideId(props.match.params.guideId);
            return <Guide />;
          }}
        />
        <Route
          exact
          path={ROUTES.GUIDE_PAGE(':guideId', ':pageId')}
          children={(props: RouteComponentProps<MatchParams>) => {
            appCuesOnNewPage();
            track('Page Opened');
            setActiveGuideId(props.match.params.guideId);
            setActivePageId(props.match.params.pageId);

            return <Guide />;
          }}
        />
        <Route
          exact
          path={ROUTES.GUIDE_PAGE_EDIT(':guideId', ':pageId')}
          children={(props: RouteComponentProps<MatchParams>) => {
            appCuesOnNewPage();
            setActiveGuideId(props.match.params.guideId);
            setActivePageId(props.match.params.pageId);
            setIsEditMode(true);

            return handleLoggedOut(<Guide />);
          }}
        />

        <Route
          exact
          path={ROUTES.MAJESTIC_FONT_EDITOR}
          children={() => {
            appCuesOnNewPage();

            return handleLoggedOut(displayContentSettingsIfContentManage());
          }}
        />
        <Route
          exact
          path={ROUTES.THEMESET_OVERVIEW}
          children={() => {
            if (isLandingPageEnabled) {
              setActiveOverviewSection(OverviewSections.THEMESETS);
              return handleLoggedOut(<OverviewV2 />);
            }

            return handleLoggedOut(displayThemesetsOverviewIfAllowed());
          }}
        />
        <Route
          exact
          path={ROUTES.THEMESET(':themesetId')}
          children={(props: any) =>
            handleLoggedOut(displayThemesetIfAllowed(props))
          }
        />
        <Route
          exact
          path={ROUTES.THEMESET_COLORS(':themesetId')}
          children={(props: any) =>
            handleLoggedOut(displayThemesetIfAllowed(props))
          }
        />
        <Route
          exact
          path={ROUTES.THEMESET_FONTS(':themesetId')}
          children={(props: any) =>
            handleLoggedOut(displayThemesetIfAllowed(props))
          }
        />
        <Route
          exact
          path={ROUTES.THEMESET_GUIDE_UI(':themesetId')}
          children={(props: any) =>
            handleLoggedOut(displayThemesetIfAllowed(props))
          }
        />
        <Route
          exact
          path={ROUTES.THEMESET_GUIDE_PROPERTIES(':themesetId')}
          children={(props: any) =>
            handleLoggedOut(displayThemesetIfAllowed(props))
          }
        />
        <Route
          path={ROUTES.SEARCH}
          children={(props: any) => {
            if (isLandingPageEnabled) {
              setActiveOverviewSection(OverviewSections.SEARCH);
              return handleLoggedOut(<OverviewV2 />);
            }

            return <Search {...props} />;
          }}
        />
        <Route
          exact
          path={ROUTES.GROUPS_OVERVIEW}
          children={() => redirectAndTrack(OverviewSections.GROUPS)}
        />
        <Route
          exact
          path={ROUTES.PAGES_OVERVIEW}
          children={() => redirectAndTrack(OverviewSections.HOMEPAGES)}
        />
        <Route
          exact
          path={[ROUTES.GUIDE_OVERVIEW, ROUTES.GUIDES_OVERVIEW]}
          children={() => redirectAndTrack(OverviewSections.GUIDES)}
        />
        {isLandingPageEnabled && (
          <Route
            exact
            path={ROUTES.CUSTOM_LINKS}
            children={() => redirectAndTrack(OverviewSections.CUSTOM_LINKS)}
          />
        )}
        <Route path="*" children={handleLoggedOut(<GuidePageNotFound />)} />
      </Switch>
    </Suspense>
  );
};

export default Router;

const useHandleOldHashedRoutes = () => {
  const history = useHistory();
  const { hash } = useLocation();
  React.useEffect(() => {
    // Regex to capture the hash of a specific section
    const rx = /([#])\w+/g;

    if (hash && hash.match('#/')) {
      const unhashedPath = hash.replace('#/', '');
      const lastHash = unhashedPath.match(rx);

      if (lastHash?.length > 0) {
        history.push({
          pathname: unhashedPath.replace(rx, ''),
          hash: lastHash[0],
        });
      } else {
        history.push({ pathname: unhashedPath.replace(rx, '') });
      }
    }
  }, [history, hash]);
};
