import React from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import { IconCancel, IconOpenInNew } from '@bynder/icons';
import { token, Button } from '@bynder/design-system';
import { useModalStore } from '../../stores/modalStore';
import { Translate } from '@bynder/localization';

export interface AssetDetailOverlayInterface {
  readonly assetName: string;
  readonly assetDetailUrl: string;
  readonly assetUrl: string;
  readonly isVisible: boolean;
  readonly showOpenAssetInAssetBank: boolean;
}

export const AssetDetailOverlay: React.FC = () => {
  const hideAssetDetailOverlay = useModalStore.use.hideAssetDetailOverlay();
  const assetDetailOverlay = useModalStore.use.assetDetailOverlay();
  const onCloseOverlay = () => hideAssetDetailOverlay();
  const {
    isVisible,
    assetName,
    assetUrl,
    showOpenAssetInAssetBank,
    assetDetailUrl,
  } = assetDetailOverlay;

  const onClickDetailUrl = () => {
    window.open(assetDetailUrl, '_blank');
  };

  return (
    isVisible && (
      <Portal>
        <StyledAssetOverlay>
          <StyledAssetOverlayHeader>
            <h1>{assetName}</h1>

            <StyledAssetOverlayActions>
              {showOpenAssetInAssetBank && (
                <Button
                  onClick={onClickDetailUrl}
                  variant="clean"
                  icon={<IconOpenInNew />}
                >
                  <span>
                    <Translate id="ASSET_DETAIL_OVERLAY.BUTTON_TEXT" />
                  </span>
                </Button>
              )}
              <Button
                aria-label="Close"
                onClick={onCloseOverlay}
                variant="clean"
                icon={<IconCancel />}
              />
            </StyledAssetOverlayActions>
          </StyledAssetOverlayHeader>

          <StyledAssetOverlayDisplay>
            <img src={assetUrl} alt={assetName} />
          </StyledAssetOverlayDisplay>
        </StyledAssetOverlay>
      </Portal>
    )
  );
};

const StyledAssetOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10001;
  background: ${token.gray1000};

  padding: ${token.spacing7} ${token.spacing8};
`;

const StyledAssetOverlayHeader = styled.div`
  display: flex;
  place-content: space-between;

  h1 {
    color: ${token.white};
    font-size: ${token.fontSize400};
    line-height: 40px;
  }
`;

const StyledAssetOverlayActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 232px;
`;

const StyledAssetOverlayDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    // We use the same rules as the Bynder Asset Bank Detail View
    max-height: calc(100vh - (170px + 40px));
    max-width: 800px;
  }
`;
