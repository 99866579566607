import {
  CSS_CLASSES,
  CSS_PROPERTIES,
} from '../../components/guides/Inspector/constants';
import { DEFAULT_CARD_WIDGET_CARD_SIZE } from '../../components/widgets/helpers';
import { generateUUID } from '../../helpers/utils';
import { getNewCard } from '../../mutations/widgets/card';
import {
  SectionLayout,
  ContentAlignment,
  WIDGET_TYPE,
  WidgetAlignment,
  GuideType,
} from '../../types';
import { Command } from '../../types/commands';
import {
  DEFAULT_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
  SectionTemplatesEnum,
  DEFAULT_TEXT_TEXT_TEMPLATE,
  DEFAULT_SECTION_TITLE,
  DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
  TITLE_HOMEPAGE_TEMPLATE,
  BUTTON_TEXT_HOMEPAGE_TEMPLATE,
  BUTTON_STYLE_HOMEPAGE_TEMPLATE,
  TEXT_HOMEPAGE_TEMPLATE,
  LINK_HOMEPAGE_TEMPLATE,
  NAVIGATION_LINKS_TITLE,
  REDIRECT_TITLE,
  CARDS_INFO_TEXT,
  COLLECTIONS_TITLE,
  COLLECTION_INFO_TEXT,
  EMBED_COLLECTION_BUTTON_TEXT,
  QUICK_LINKS_TITLE,
  EXPRESS_INFO_TEXT,
  EXPRESS_TITLE,
  VIDEOS_TITLE,
  VIDEOS_INFO_TEXT,
} from '../sections';
import { emptyCSSObject } from '../widgets';

export const TemplateBatchCommands = (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
  sectionTemplate: SectionTemplatesEnum,
  sectionPosition: number,
  guideTitle?: string,
) => {
  const {
    addNewSectionToPage,
    changeSectionLayout,
    editSectionWidth,
    editSectionHeight,
    editSectionContentAlignment,
    editSectionHorizontalPadding,
    addNewTextWidgetToSection,
    editSectionContentSpacing,
    editSectionColumnSpacing,
    addNewButtonWidgetToSection,
    addNewCardWidgetToSection,
    addNewButtonsToButtonWidget,
    createLandingPage,
    editSectionVerticalPadding,
    addNewYoutubeWidgetToSection,
  } = BatchCommands(guideId, chapterId, pageId, sectionId, guideVersion);

  switch (sectionTemplate) {
    case SectionTemplatesEnum.LANDING_PAGE_TEMPLATE: {
      const secondSectionId = generateUUID();
      const thirdSectionId = generateUUID();
      const fourthSectionId = generateUUID();
      const fifthSectionId = generateUUID();
      const sixthSectionId = generateUUID();
      const seventhSectionId = generateUUID();

      return [
        // first section
        createLandingPage(guideTitle),
        editSectionWidth(1200),
        editSectionHeight(120),
        editSectionVerticalPadding(96),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(TITLE_HOMEPAGE_TEMPLATE),
        addNewTextWidgetToSection(TEXT_HOMEPAGE_TEMPLATE),
        addNewButtonWidgetToSection(
          BUTTON_TEXT_HOMEPAGE_TEMPLATE,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
        ),

        // second section
        addNewSectionToPage(1, secondSectionId),
        editSectionWidth(1200, secondSectionId),
        editSectionHeight(120, secondSectionId),
        editSectionVerticalPadding(48, secondSectionId),
        addNewTextWidgetToSection(REDIRECT_TITLE, 0, secondSectionId),
        addNewTextWidgetToSection(CARDS_INFO_TEXT, 0, secondSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Asset Category A', "", "https://community.bynder.com/"),
            getNewCard(generateUUID(), 'Asset Category B', "", "https://support.bynder.com/hc/en-us"),
            getNewCard(generateUUID(), 'Asset Category C', "", "https://www.bynder.com/en/"),
            getNewCard(generateUUID(), 'Asset Category D'),
          ],
          0,
          WidgetAlignment.CENTER,
          secondSectionId,
        ),

        // third section
        addNewSectionToPage(2, thirdSectionId),
        editSectionWidth(1200, thirdSectionId),
        editSectionHeight(120, thirdSectionId),
        editSectionVerticalPadding(48, thirdSectionId),
        addNewTextWidgetToSection(COLLECTIONS_TITLE, 0, thirdSectionId),
        addNewTextWidgetToSection(COLLECTION_INFO_TEXT, 0, thirdSectionId),
        addNewButtonWidgetToSection(
          EMBED_COLLECTION_BUTTON_TEXT,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          thirdSectionId,
        ),

        // fourth section
        addNewSectionToPage(3, fourthSectionId),
        editSectionWidth(1200, fourthSectionId),
        editSectionVerticalPadding(48, fourthSectionId),
        editSectionContentAlignment(ContentAlignment.CENTER, fourthSectionId),
        addNewTextWidgetToSection(QUICK_LINKS_TITLE, 0, fourthSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Community', "", "https://community.bynder.com/"),
            getNewCard(generateUUID(), 'Knowledge base', "", "https://support.bynder.com/hc/en-us"),
            getNewCard(generateUUID(), 'Bynder website', "", "https://www.bynder.com/en/"),
          ],
          0,
          WidgetAlignment.CENTER,
          fourthSectionId,
        ),

        // fifth section
        addNewSectionToPage(4, fifthSectionId),
        editSectionWidth(1200, fifthSectionId),
        editSectionVerticalPadding(48, fifthSectionId),
        editSectionContentAlignment(ContentAlignment.CENTER, fifthSectionId),
        changeSectionLayout(SectionLayout.DOUBLE_50_50, fifthSectionId),
        addNewTextWidgetToSection(EXPRESS_TITLE, 0, fifthSectionId),
        addNewTextWidgetToSection(EXPRESS_INFO_TEXT, 0, fifthSectionId),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), 'Share files')],
          1,
          WidgetAlignment.CENTER,
          fifthSectionId,
        ),

        // sixth section
        addNewSectionToPage(5, sixthSectionId),
        editSectionWidth(1200, sixthSectionId),
        editSectionHeight(120, sixthSectionId),
        editSectionVerticalPadding(48, sixthSectionId),
        addNewTextWidgetToSection(VIDEOS_TITLE, 0, sixthSectionId),
        addNewTextWidgetToSection(VIDEOS_INFO_TEXT, 0, sixthSectionId),
        addNewYoutubeWidgetToSection(0, sixthSectionId),

        // seventh section
        addNewSectionToPage(6, seventhSectionId),
        editSectionWidth(1200, seventhSectionId),
        editSectionHeight(120, seventhSectionId),
        editSectionHorizontalPadding(16, seventhSectionId),
        editSectionVerticalPadding(48, seventhSectionId),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33, seventhSectionId),
        addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 0, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
        addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 1, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
        addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 2, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
      ];
    }
    case SectionTemplatesEnum.HERO_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionHeight(600),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_TEXT_HERO_TEMPLATE),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
        ),
      ];

    case SectionTemplatesEnum.TWO_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case SectionTemplatesEnum.FOUR_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case SectionTemplatesEnum.SIX_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
      ];
    case SectionTemplatesEnum.TEXT_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
        editSectionHorizontalPadding(16),
        editSectionContentSpacing(8),
        editSectionColumnSpacing(20),
      ];
    case SectionTemplatesEnum.TWO_COLUMN_FEATURED_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), '', '')],
          1,
          WidgetAlignment.LEFT,
        ),
      ];
    case SectionTemplatesEnum.HERO_WITH_IMAGE_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
        ),
        addNewButtonsToButtonWidget(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), '', '')],
          1,
          WidgetAlignment.LEFT,
        ),
      ];
    }
    case SectionTemplatesEnum.FOOTER_WITH_IMAGE_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection([getNewCard(generateUUID(), '', '')], 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
      ];
  }
};

export const BatchCommands = (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
) => {
  return {
    createLandingPage: (guideTitle: string) => ({
      command: Command.CREATE_GUIDE_WITH_TEMPLATE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
        chapterId,
        chapterTitle: 'Chapter',
        pageId,
        pageTitle: 'Page',
        sectionId,
        guideType: GuideType.SINGLE_PAGE,
      },
    }),

    addNewCardWidgetToSection: (
      cardPayloads: object[],
      columnIndex: number = 0,
      widgetAlignment: WidgetAlignment = WidgetAlignment.CENTER,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_CARD_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        columnIndex,
        widgetId: generateUUID(),
        cardWidgetPayload: {
          cardPayloads,
          widgetStyle: {
            ...emptyCSSObject,
            stylesheet: `${CSS_CLASSES.CARD}{\n${CSS_PROPERTIES.WIDTH}:${DEFAULT_CARD_WIDGET_CARD_SIZE};\n}\n${CSS_PROPERTIES.JUSTIFY_CONTENT}:${widgetAlignment};\n`,
          },
        },
      },
    }),
    changeSectionLayout: (
      sectionLayout: SectionLayout,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.CHANGE_SECTION_LAYOUT,
      aggregateId: guideId,
      aggregateVersion: 0,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionLayout,
      },
    }),
    editSectionHorizontalPadding: (
      sectionHorizontalPadding: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_HORIZONTAL_PADDING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionHorizontalPadding,
      },
    }),
    editSectionVerticalPadding: (
      sectionVerticalPadding: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_VERTICAL_PADDING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionVerticalPadding,
      },
    }),
    editSectionContentSpacing: (
      sectionContentSpacing: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_CONTENT_SPACING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionContentSpacing,
      },
    }),
    editSectionColumnSpacing: (
      sectionColumnSpacing: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_COLUMN_SPACING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionColumnSpacing,
      },
    }),
    addNewSectionToPage: (
      sectionPosition: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_SECTION_TO_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionPosition,
      },
    }),
    editSectionWidth: (
      sectionWidth: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_WIDTH,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionWidth,
      },
    }),
    editSectionHeight: (
      sectionHeight: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_HEIGHT,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionHeight,
      },
    }),
    editSectionContentAlignment: (
      sectionContentAlignment: ContentAlignment,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_CONTENT_ALIGNMENT,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionContentAlignment,
      },
    }),
    addNewTextWidgetToSection: (
      widgetText: string,
      columnIndex: number = 0,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_TEXT_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId: generateUUID(),
        widgetType: WIDGET_TYPE.TEXT,
        columnIndex,
        widgetText,
      },
    }),
    addNewYoutubeWidgetToSection: (
      columnIndex: number = 0,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_YOUTUBE_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId: generateUUID(),
        columnIndex,
        youtubeWidgetPayload: {
          sourceId: 'BSprLGppjLk',
          settings: {
            loop: false,
            autoplay: false,
            controls: true,
          },
        },
      },
    }),
    addNewButtonsToButtonWidget: (
      buttonText: string,
      buttonStylesheet: string,
      widgetId: string = generateUUID(),
    ) => ({
      command: Command.ADD_NEW_BUTTONS_TO_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        columnIndex: 0,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: generateUUID(),
              name: {
                text: buttonText,
              },
              link: {
                url: '',
                openNewTab: false,
              },
              buttonStyle: {
                stylesheet: buttonStylesheet,
                language: 'css',
                version: 3,
              },
            },
          ],
        },
      },
    }),
    addNewButtonWidgetToSection: (
      buttonText: string,
      buttonStylesheet: string,
      widgetId: string = generateUUID(),
      widgetStylesheet: string = '',
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_BUTTON_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        columnIndex: 0,
        widgetId,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: generateUUID(),
              name: {
                text: buttonText,
              },
              link: {
                url: '',
                openNewTab: false,
              },
              buttonStyle: {
                stylesheet: buttonStylesheet,
                language: 'css',
                version: 3,
              },
            },
          ],
          widgetStyle: {
            stylesheet: widgetStylesheet,
            language: 'CSS',
            version: '3',
          },
        },
      },
    }),
  };
};
