import { ReactNode } from 'react';
import { RawDraftContentState } from 'draft-js';
import { BaseCommand, SectionBackground, StyleSheet } from '.';
import { DefaultColorsEnum, SpacingWidgetOptions } from '../constants/widgets';
import { Command } from './commands';
import type { CollectionWidget } from './widgets/collections';
import { VimeoSettingsType } from '../mutations/widgets/vimeo';

export enum WIDGET_TYPE {
  TITLE = 'title',
  TEXT = 'text',
  IMAGE = 'image',
  COLOR_PALETTE = 'color',
  VIDEO = 'video',
  COLLECTION = 'collection',
  TABLE = 'table',
  SPACER = 'spacing',
  AUDIO = 'audio',
  PERSONAL_FILE = 'file',
  BUTTON = 'button',
  CARD = 'card',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
}

export interface BaseWidget {
  id: string;
  type: WIDGET_TYPE;
  creationTime?: string;
  lastModifiedTime?: string;
  position?: number;
}

export interface ContentImageWidget {
  assetId?: string;
  assetName?: string;
  imageUrl?: string;
  detailUrl?: string;
  assetExtension?: string;
  imageAction?: ImageAction;
}

export interface ImageAction {
  type: string;
  openNewTab?: boolean;
  url?: string;
}

export interface ContentVideoWidget {
  assetId?: string;
  assetName?: string;
  videoUrl?: string;
  detailUrl?: string;
  coverUrl?: string;
  controls?: boolean;
  muted?: boolean;
  autoplay?: boolean;
  loop?: boolean;
}

export interface ContentTextWidget {
  text?: RawDraftContentState;
}

export interface ContentTableWidget {
  rows: Row[];
  properties: TableProperties;
}

export interface ContentTableWidgetCell {
  content?: RawDraftContentState;
}

export interface ContentTitleWidget {
  title?: RawDraftContentState;
}

export interface ContentColorPaletteWidget {
  colors: ColorPalette[];
  defaultColorCodes: ColorValuesShown;
  style: StyleSheet;
}

export interface ColorPalette {
  id?: string;
  name?: string;
  colorCodes?: ColorCode[];
  defaultColorCodes?: ColorValuesShown;
}

export interface ColorCode {
  key?: string;
  value?: string;
}

export type ColorValuesShown = {
  [index in DefaultColorsEnum]?: boolean;
};

export interface ContentSpacerWidget {
  height?: number;
  divider?: SpacingWidgetOptions;
  style?: StyleSheet;
}

export interface ContentYoutubeWidget {}

export interface ImageWidget extends BaseWidget, ContentImageWidget {}

export interface VideoWidget extends BaseWidget, ContentVideoWidget {}

export interface TextWidget extends BaseWidget, ContentTextWidget {}

export interface YoutubeWidgetSettings {
  loop: boolean;
  autoplay: boolean;
  controls: boolean;
}
export interface YoutubeWidget extends BaseWidget, ContentYoutubeWidget {
  sourceId: string;
  settings: YoutubeWidgetSettings;
}
export interface TableWidget extends BaseWidget {
  table: ContentTableWidget;
}

export interface TableProperties {
  numRows: number;
  numColumns: number;
}

export interface Row {
  cells: Cell[];
}

export interface Cell extends ContentTableWidgetCell {
  properties?: NonNullable<unknown>;
}

export interface TitleWidget extends BaseWidget, ContentTitleWidget {}

export interface ColorPaletteWidget
  extends BaseWidget,
    ContentColorPaletteWidget {}

export interface SpacerWidget extends BaseWidget, ContentSpacerWidget {}

export interface AudioWidget extends BaseWidget, CommandAudioWidget {}

export interface ButtonWidget extends BaseWidget, CommandButtonWidget {}

export interface CardWidget extends BaseWidget, CommandCardWidget {}

export interface VimeoWidget extends BaseWidget, CommandCardWidget {
  sourceId: string;
  settings: VimeoSettingsType;
}

export interface PersonalFileWidget
  extends BaseWidget,
    CommandPersonalFileWidget {
  progress: number;
}

export interface CommandImageWidget {
  widgetAssetId?: string;
  widgetAssetName?: string;
  widgetImageUrl?: string;
  widgetDetailUrl?: string;
  widgetAssetExtension?: string;
}

export interface CommandVideoWidget {
  widgetAssetId?: string;
  widgetAssetName?: string;
  widgetVideoUrl?: string;
  widgetDetailUrl?: string;
  widgetCoverUrl?: string;
}

export interface CommandAudioWidget {
  assetId?: string;
  assetName?: string;
  audioUrl?: string;
  detailUrl?: string;
}
export interface CommandButtonWidget {
  isLegacy?: boolean;
  buttons: ButtonWidgetButton[];
  style?: StyleSheet;
}

export interface ButtonWidgetButton {
  id: string;
  name: {
    text: string;
  };
  link: {
    url: string;
    openNewTab: boolean;
  };
  style?: StyleSheet;
}

export enum WidgetAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  SPACE_AROUND = 'space-between',
}
export interface CommandCardWidget {
  cards: CardWidgetCard[];
  style: {
    size: number;
    alignment: WidgetAlignment;
    stylesheet: string;
    version: number;
    language: string;
  };
}

export interface UploadedFile {
  fileBlob: string;
  filename: string;
  fileSize: number;
  height: number;
  width: number;
}

export interface CardWidgetCard {
  id: string;
  headline: {
    title: string;
    description: string;
  };
  link: {
    url: string;
    openNewTab: boolean;
  };
  cardStyle: StyleSheet;
  asset: SectionBackground;
}

export interface CommandPersonalFileWidget {
  file: UploadedFile;
}

export interface CommandTextWidget {
  widgetText?: RawDraftContentState;
}

export interface CommandTitleWidget {
  widgetTitle?: RawDraftContentState;
}

export interface CommandTableWidgetCell {
  rowIndex: number;
  columnIndex: number;
  content: RawDraftContentState;
}
export interface CommandTableWidget {
  cellContentPayloads: [CommandTableWidgetCell];
}

export interface CommandTableWidgetInsertRow {
  insertRowPayload: {
    rowIndex: number;
  };
}

export interface CommandTableWidgetInsertColumn {
  insertColumnPayload: {
    columnIndex: number;
  };
}

export interface CommandTableWidgetRemoveColumn {
  widgetRemoveColumnPayload: {
    columnIndex: number;
  };
}

export interface CommandTableWidgetRemoveRow {
  widgetRemoveRowPayload: {
    rowIndex: number;
  };
}

export interface WidgetsReducerState {
  readonly widgets: WidgetMap;
}

export interface WidgetConstant {
  label: string;
  type: WIDGET_TYPE;
  icon: ReactNode;
  'data-testid'?: string;
}

export interface WidgetMap {
  [key: string]: WidgetTypes;
}

export type WidgetTypes =
  | ColorPaletteWidget
  | ImageWidget
  | TextWidget
  | SpacerWidget
  | TitleWidget
  | CollectionWidget
  | AudioWidget
  | PersonalFileWidget;

export type BaseWidgetCommand = {
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
};

export type CreateWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    widgetType: WIDGET_TYPE;
    columnIndex: number;
  };

export type UpdateWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    widgetType: WIDGET_TYPE;
    prefix: string;
  };

export type DeleteWidgetCommand = BaseCommand & BaseWidgetCommand;

export type MoveWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    to: {
      sectionId: string;
      columnIndex: number;
      position: number;
    };
  };

export type AddColorToColorWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    widgetColorName: string;
    widgetColorCodes: ColorCode[];
  };

export type RemoveColorFromColorWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    widgetColorPosition: number;
  };

export type EditColorInColorWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    widgetColorName: string;
    widgetColorPosition: number;
    widgetColorCodes: ColorCode[];
  };

export type EditCellInTableWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
  } & CommandTableWidget;

export type InsertRowInTableWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
  } & CommandTableWidgetInsertRow;

export type InsertColumnInTableWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
  } & CommandTableWidgetInsertColumn;

export type RemoveColumnFromTableWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
  } & CommandTableWidgetRemoveColumn;

export type RemoveRowFromTableWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
  } & CommandTableWidgetRemoveRow;

export type SetDefaultColorCodesInColorPaletteWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    colorValuesShown: ColorValuesShown;
  };

export type EditDividerInSpacingWidgetCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    divider: SpacingWidgetOptions;
  };

export type SetImageActionCommand = BaseCommand &
  BaseWidgetCommand & {
    columnIndex: number;
    commandType: Command;
    imageActionUrl?: string;
    imageActionNewTab?: boolean;
  };

export type WidgetCommands =
  | CreateWidgetCommand
  | UpdateWidgetCommand
  | DeleteWidgetCommand
  | AddColorToColorWidgetCommand
  | RemoveColorFromColorWidgetCommand
  | EditColorInColorWidgetCommand
  | EditCellInTableWidgetCommand
  | InsertRowInTableWidgetCommand
  | InsertColumnInTableWidgetCommand
  | RemoveColumnFromTableWidgetCommand
  | SetDefaultColorCodesInColorPaletteWidgetCommand;
