import { Identity, IdentityType } from '.';
import { LegacyModificationGroup } from '../helpers/permissions';

export enum EntityPermissions {
  VIEW = 'Viewer',
  EDIT = 'Editor',
}

export enum PermissionDocumentType {
  GUIDE = 'GUIDE',
  GROUP = 'GROUP',
  PAGE = 'PAGE',
  CHAPTER = 'CHAPTER',
}

export enum RoleType {
  READER = 'READER',
  EDITOR = 'EDITOR',
}

export enum EntityVisibility {
  SPECIFIC = 'SPECIFIC',
  PORTAL = 'PORTAL',
  PUBLIC = 'PUBLIC',
}

export interface Permission {
  id: string;
  identity?: IdentityType;
  role: RoleType;
  inherited: boolean;
}

export interface EntityPermission {
  documentId: string;
  documentOwnerId: string;
  documentType: PermissionDocumentType;
  documentVisibility: EntityVisibility;
  permissions: Permission[];
}

export interface EntityPermissionsMap {
  [key: string]: EntityPermission;
}

export interface IdentityAccess extends Permission, Identity {}

export type LegacyEditPermissionCommand = {
  aggregateId: string;
  viewAccessListChanges: LegacyModificationGroup;
  editAccessListChanges: LegacyModificationGroup;
  guideVisibility: EntityVisibility;
  aggregateVersion?: number;
};

export enum PermissionType {
  Guides = 'guides',
  Groups = 'groups',
}
