import { JWT_PERMISSIONS } from './permissions';
import { useCurrentUserIdentity, useDecodedToken } from '../queries/identity';
import { useState, useRef, useEffect } from 'react';
import { isDevelopment } from '../constants/app';

export const useEllipsedTooltip = () => {
  const [hasEllipsedName, setHasEllipsedName] = useState(false);
  const tooltipRef = useRef<HTMLElement>();

  useEffect(() => {
    if (tooltipRef.current) {
      setHasEllipsedName(
        tooltipRef.current.scrollWidth > tooltipRef.current.offsetWidth,
      );
    }
  }, []);

  return { hasEllipsedName, tooltipRef };
};

export const useModuleTier = () => {
  const isBasicTierUser = (
    permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
  ) =>
    permission === JWT_PERMISSIONS.GUIDELINES_CREATE ||
    permission === JWT_PERMISSIONS.GUIDELINES_VIEW ||
    permission === JWT_PERMISSIONS.GUIDELINES_MANAGE;

  const isAdvancedTierUser = (
    permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
  ) =>
    permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_CREATE ||
    permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_VIEW ||
    permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_MANAGE;
  const isEnterpriseTierUser = (
    permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
  ) =>
    permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_CREATE ||
    permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_VIEW ||
    permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_MANAGE;

  const decodedToken = useDecodedToken();

  const isEnterprise =
    decodedToken?.module_permissions?.some(isEnterpriseTierUser);
  const isAdvanced =
    decodedToken?.module_permissions?.some(isAdvancedTierUser) && !isEnterprise;
  const isBasic =
    decodedToken?.module_permissions?.some(isBasicTierUser) &&
    !isEnterprise &&
    !isAdvanced;

  const tier = isEnterprise ? 'Enterprise' : isAdvanced ? 'Advanced' : 'Basic';

  return { isBasic, isAdvanced, isEnterprise, tier };
};

export const useHasPermissions = (permissions: string | string[]) => {
  let permissionCollection = permissions;

  if (typeof permissions === 'string') {
    permissionCollection = [permissions];
  }

  const decodedToken = useDecodedToken();

  if (isDevelopment) return true;
  if (!decodedToken) return false;

  let match = true;

  for (const element of permissionCollection) {
    const { module_permissions } = decodedToken;

    if (!module_permissions.includes(element)) {
      match = false;
      break;
    }
  }

  return match;
};

export enum UserType {
  PUBLIC = 'PUBLIC',
  OPEN_ASSET_BANK = 'OPEN_ASSET_BANK',
  LOGGED_IN = 'LOGGED_IN',
  UNKNOWN = 'UNKNOWN',
}

export const useUserType = (): {
  userType: UserType;
  isLoading: boolean;
} => {
  const { data: currentUser, isLoading, isError } = useCurrentUserIdentity();
  let userType = UserType.UNKNOWN;

  if (isError) userType = UserType.PUBLIC;

  if (!isLoading) {
    if (currentUser?.public_user === 'True')
      userType = UserType.OPEN_ASSET_BANK;
    else if (currentUser?.public_user === 'False')
      userType = UserType.LOGGED_IN;
  }

  return {
    userType,
    isLoading,
  };
};
