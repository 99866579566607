import { useQuery } from '@tanstack/react-query';
import { fetchEntityPermissionsApi } from '../helpers/api/permissionsApi';
import { EntityPermission } from '../types';

export const PERMISSIONS_KEYS = {
  ENTITY_PERMISSIONS: 'ENTITY_PERMISSIONS',
};

export const useEntityPermission = (entityId: string) => {
  return useQuery<EntityPermission>(
    [PERMISSIONS_KEYS.ENTITY_PERMISSIONS, entityId],
    () => fetchEntityPermissionsApi(entityId),
    { enabled: !!entityId, staleTime: Infinity },
  );
};
