import React from 'react';
import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  ModalBase,
  token,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';
import landing_page from '../../assets/landing_page.png';
import styled from 'styled-components';

export interface NewCreateModalProps {
  entityType: EntityEnum;
  onCancel: () => void;
  onSubmit: (title: string, type: EntityEnum) => void;
  maxLength?: number;
}

export const enum EntityEnum {
  LANDING_PAGE = 'LANDING_PAGE',
  GUIDE = 'GUIDE',
  GROUP = 'GROUP',
}

const NewCreateModal: React.FC<NewCreateModalProps> = ({
  entityType,
  onCancel,
  onSubmit,
  maxLength,
}) => {
  const { translate } = useTranslate();
  const [title, setTitle] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    (document.activeElement as HTMLElement).blur();

    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && title) {
      onSubmit(title, entityType);
    }
  };

  const modalTitle = {
    [EntityEnum.GROUP]: translate('CREATE_MODAL.NEW_GROUP_HEADER_TEXT'),
    [EntityEnum.GUIDE]: translate('CREATE_MODAL.NEW_GUIDE_HEADER_TEXT'),
    [EntityEnum.LANDING_PAGE]: translate('CREATE_MODAL.NEW_PAGE_HEADER_TEXT'),
  };

  const modalDescription = {
    [EntityEnum.GROUP]: translate('CREATE_MODAL.NEW_GROUP_BUTTON_TEXT'),
    [EntityEnum.GUIDE]: translate('CREATE_MODAL.NEW_GUIDE_BUTTON_TEXT'),
    [EntityEnum.LANDING_PAGE]: translate('CREATE_MODAL.NEW_PAGE_BUTTON_TEXT'),
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header
        title={modalTitle[entityType]}
        secondaryInfo={modalDescription[entityType]}
      />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="modalInput">Title</Form.Label>
          <div>
            <Input
              onKeyDown={handleKeyPress}
              inputRef={inputRef}
              id="modalInput"
              placeholder="Enter a title"
              value={title}
              onChange={setTitle}
              data-testid="modalInput"
              maxLength={maxLength}
              iconRight={
                <MaxCharacterCounter value={title} maxValue={maxLength} />
              }
            />
          </div>
        </Form.Group>

        <Flex direction="column" alignItems="center">
          <Grid>
            {entityType === EntityEnum.LANDING_PAGE && (
              <Card variant="clean">
                <Card.Frame>
                  <Card.Images>
                    <Card.Img src={landing_page} />
                  </Card.Images>
                </Card.Frame>

                <Card.Header
                  isNotTruncated
                  title={translate('CREATE_MODAL.LANDING_PAGE.CARD_TITLE')}
                  detail="Single-page layout"
                />
              </Card>
            )}
          </Grid>
        </Flex>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            isDisabled={title === ''}
            onClick={() => onSubmit(title, entityType)}
          >
            {translate('CREATE_GROUP_MODAL.DEFAULT_BUTTON_TEXT')}
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

const Grid = styled.div<{ size?: string }>`
  display: grid;
  gap: ${token.spacing6};
  align-items: flex-start;
  margin-top: 12px;
  width: 100%;
`;

export default NewCreateModal;
