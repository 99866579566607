import axios from './axios';
import decode from 'jwt-decode';
import { memoize } from 'lodash';
import { getJWT, invalidateAccessToken } from '@bynder/js-access-token-lib';
import { updateAbilities } from './abilities';
import { API_ROUTES } from '../constants/app';

const isDevelopment = import.meta.env.MODE === 'development';

// If in development mode, remove token on start so permissions can be tested
if (isDevelopment) {
  invalidateAccessToken();
}

export type BynderJWT = {
  user_id: string;
  account_id: string;
  module_permissions: string[];
  profile_id: string;
  iat: number;
  exp: number;
  aud: string;
  iss: string;
  jti: string;
};

export const fetchToken = async (): Promise<string> => {
  let token;

  try {
    if (isDevelopment) {
      token = await getMockedToken();
      updateAbilities(token);
    } else {
      token = await getJWT();
      updateAbilities(token);
    }

    return token;
  } catch (e) {
    console.warn('Could not retrieve token');

    return null;
  }
};

const getMockedToken = async () => {
  const axiosc = await axios(false);
  const permission =
    localStorage.getItem('DEV_PERMISSION') || import.meta.env.VITE_PERMISSION;

  const response = await axiosc.get(
    API_ROUTES.TOKEN_MOCK(
      import.meta.env.VITE_ACCOUNT_ID,
      import.meta.env.VITE_USER_ID,
      import.meta.env.VITE_PROFILE_ID,
      permission,
    ),
  );

  return response?.data?.access_token;
};

export const getToken = memoize(fetchToken);

export const getUserId = (token: string): string => {
  try {
    const payload = decode<BynderJWT>(token);

    return payload.user_id;
  } catch (e) {
    return ''; // if token is invalid, assume public user
  }
};
