/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { useMutation } from '@tanstack/react-query';
import { produce, createDraft } from 'immer';
import { findIndex, remove } from 'lodash';
import { CONFIG, queryClient } from '../constants/app';
import {
  addSectionTemplate,
  addSectionToPage,
  changeSectionAlignmentApi,
  changeSectionLayout,
  deleteSectionFromPage,
  duplicateSectionInPage,
  editBackgroundColorInSection,
  editSectionBackgroundImage,
  editSectionColumnGapApi,
  editSectionContentSpacingApi,
  editSectionHeightApi,
  editSectionHorizontalPaddingApi,
  editSectionStyleApi,
  editSectionVerticalPaddingApi,
  editSectionWidthApi,
  moveSection,
  removeSectionBackgroundImage,
} from '../helpers/api/guidelinesApi';
import { generateRandomTextBlockKey, generateUUID } from '../helpers/utils';
import { GUIDE_KEYS } from '../queries/guides';
import {
  Column,
  ContentAlignment,
  Guide,
  Page,
  SectionLayout,
  Section,
  TextBlockMutationPayload,
  TextWidget,
  ToSectionPayload,
  WidgetMutationsPayload,
  WidgetTypes,
  WIDGET_TYPE,
  SectionBackground,
} from '../types';
import { handleMutationSuccess, updateGuideVersion } from './helpers';
import {
  DEFAULT_SECTION_COLUMN_GAP,
  DEFAULT_SECTION_CONTENT_SPACING,
  DEFAULT_SECTION_HEIGHT,
  DEFAULT_SECTION_HORIZONTAL_PADDING,
  DEFAULT_SECTION_VERTICAL_PADDING,
  DEFAULT_SECTION_WIDTH,
  SectionTemplatesEnum,
} from '../constants/sections';
import { InspectorSetting } from '../constants/guides';
import { useGuideStore } from '../stores/guideStore';
import { useInspectorStore } from '../stores/inspectorStore';
import { retrieveAssetInfoApi } from '../helpers/api/uploadApi';
import { useUploaderStore } from '../stores/uploaderStore';
import { emptyCSSObject } from '../constants/widgets';
import { TemplateBatchCommands } from '../constants/app/batchCommands';

export const useSectionMutations = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activePageId = useGuideStore(s => s.activePageId);
  const activeChapterId = useGuideStore(s => s.activeChapterId);
  const setActiveSectionId = useGuideStore(s => s.setActiveSectionId);
  const clearUploadingImage = useUploaderStore.use.clearUploadingImage();
  const setActiveInspectorSetting = useInspectorStore(
    s => s.setActiveInspectorSetting,
  );
  const guide: Guide = queryClient.getQueryData([
    GUIDE_KEYS.GUIDE_BY_ID,
    activeGuideId,
  ]);

  return {
    addSection: useMutation(
      (mutationOptions: { sectionId: string; sectionPosition: number }) => {
        return addSectionToPage(
          activeGuideId,
          activeChapterId,
          activePageId,
          mutationOptions.sectionId,
          mutationOptions.sectionPosition,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyAddNewSection(
            activeGuideId,
            activePageId,
            variables.sectionId,
            variables.sectionPosition,
          );
          setActiveSectionId(variables.sectionId);
          setActiveInspectorSetting(InspectorSetting.SECTION_VIEW);
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    deleteSection: useMutation(
      (mutationOptions: { sectionId: string }) =>
        deleteSectionFromPage(
          activeGuideId,
          activeChapterId,
          activePageId,
          mutationOptions.sectionId,
          guide?.version,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyDeleteSection(
            activeGuideId,
            activePageId,
            variables.sectionId,
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Section Deleted', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    changeColumnLayout: useMutation(
      (mutationOptions: { sectionId: string; sectionLayout: SectionLayout }) =>
        changeSectionLayout(
          activeGuideId,
          activeChapterId,
          activePageId,
          mutationOptions.sectionId,
          mutationOptions.sectionLayout,
          guide?.version,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyChangeSectionLayout(
            activeGuideId,
            activePageId,
            variables.sectionId,
            variables.sectionLayout,
          );
        },

        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    placeAssetInSectionBackground: useMutation(
      (options: {
        sectionId: string;
        assetId: string;
        assetName: string;
        detailUrl: string;
        imageUrl: string;
        extension: string;
      }) =>
        editSectionBackgroundImage({
          guideId: activeGuideId,
          chapterId: activeChapterId,
          sectionId: options.sectionId,
          pageId: activePageId,
          cover: {
            assetId: options.assetId,
            assetName: options.assetName,
            detailUrl: options.detailUrl,
            imageUrl: options.imageUrl,
            extension: options.extension,
          },
          guideVersion: guide?.version,
        }),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdateSectionBackground(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              id: variables.assetId,
              name: variables.assetName,
              detailUrl: variables.detailUrl,
              imageUrl: variables.imageUrl,
            },
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Page Section Edited', data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    removeAssetFromSectionBackground: useMutation(
      (options: { sectionId: string }) =>
        removeSectionBackgroundImage({
          guideId: activeGuideId,
          chapterId: activeChapterId,
          pageId: activePageId,
          sectionId: options.sectionId,
          guideVersion: guide?.version,
        }),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyRemoveSectionBackground(
            activeGuideId,
            activePageId,
            variables.sectionId,
          );
        },
        onSuccess(data) {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),

    editSectionStyle: useMutation(
      (mutationOptions: { sectionId: string; style: string }) => {
        return editSectionStyleApi({
          guideId: activeGuideId,
          chapterId: activeChapterId,
          pageId: activePageId,
          sectionId: mutationOptions.sectionId,
          style: mutationOptions.style,
          guideVersion: guide?.version,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyUpdateSectionStyle(
            activeGuideId,
            activePageId,
            variables.sectionId,
            variables.style,
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),

    changeBackgroundColor: useMutation(
      (mutationOptions: { sectionId: string; backgroundColorId: string }) =>
        editBackgroundColorInSection(
          activeGuideId,
          activeChapterId,
          activePageId,
          mutationOptions.sectionId,
          mutationOptions.backgroundColorId,
          guide?.version,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyChangeSectionBackgroundColor(
            activeGuideId,
            activePageId,
            variables.sectionId,
            variables.backgroundColorId,
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    moveSection: useMutation(
      (mutationOptions: {
        sectionId: string;
        sectionIndex: number;
        to: ToSectionPayload;
      }) =>
        moveSection(
          activeGuideId,
          activeChapterId,
          activePageId,
          mutationOptions.sectionId,
          mutationOptions.to,
          guide?.version,
        ),
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyMoveSection(
            activeGuideId,
            activePageId,
            variables.to,
            variables.sectionIndex,
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    duplicateSection: useMutation(
      (options: {
        sectionId: string;
        sectionIndex: number;
        duplicatedSection: Section;
        widgetMutations: WidgetMutationsPayload[];
        textBlockMutations: TextBlockMutationPayload[];
      }) => {
        return duplicateSectionInPage(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          {
            sectionId: options.duplicatedSection.id,
            sectionPosition: options.sectionIndex + 1,
            widgetMutations: options.widgetMutations,
            textBlockMutations: options.textBlockMutations,
          },
          guide?.version,
        );
      },
      {
        onMutate: () => {
          updateGuideVersion(activeGuideId);
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    changeSectionAlginment: useMutation(
      (options: { sectionId: string; newAlignment: ContentAlignment }) => {
        return changeSectionAlignmentApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.newAlignment,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            { contentAlignment: variables.newAlignment },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editSectionWidth: useMutation(
      (options: { sectionId: string; sectionWidth: number }) => {
        return editSectionWidthApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.sectionWidth,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            { width: variables.sectionWidth },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editSectionHeight: useMutation(
      (options: { sectionId: string; sectionHeight: number }) => {
        return editSectionHeightApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.sectionHeight,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            { height: variables.sectionHeight },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editSectionVerticalPadding: useMutation(
      (options: { sectionId: string; verticalPadding: number }) => {
        return editSectionVerticalPaddingApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.verticalPadding,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              paddingTop: variables.verticalPadding,
              paddingBottom: variables.verticalPadding,
            },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editSectionHorizontalPadding: useMutation(
      (options: { sectionId: string; horizontalPadding: number }) => {
        return editSectionHorizontalPaddingApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.horizontalPadding,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              paddingLeft: variables.horizontalPadding,
              paddingRight: variables.horizontalPadding,
            },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    editSectionContentSpacing: useMutation(
      (options: { sectionId: string; contentSpacing: number }) => {
        return editSectionContentSpacingApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.contentSpacing,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              contentSpacing: variables.contentSpacing,
            },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    uploadAssetInSectionBackground: useMutation(
      (options: { mediaId: string; sectionId: string }) => {
        return retrieveAssetInfoApi(options.mediaId);
      },
      {
        retry: true,
        onSuccess: (data, variables) => {
          clearUploadingImage(variables.sectionId);
          updateGuideVersion(activeGuideId);
          optimisticallyUpdateSectionBackground(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              id: data.id,
              name: data.name,
              detailUrl: `/media/?mediaId=${data.id}`,
              imageUrl: data.original,
            },
          );
          editSectionBackgroundImage({
            guideId: activeGuideId,
            chapterId: activeChapterId,
            sectionId: variables.sectionId,
            pageId: activePageId,
            cover: {
              assetId: data.id,
              assetName: data.name,
              detailUrl: `${CONFIG.ACCOUNT_BASE_ROUTE}/media/?mediaId=${data.id}`,
              imageUrl: data.original,
              extension: data.extension[0],
            },
            guideVersion: guide?.version,
          });
        },
      },
    ),
    editSectionColumnGap: useMutation(
      (options: { sectionId: string; columnGap: number }) => {
        return editSectionColumnGapApi(
          activeGuideId,
          activeChapterId,
          activePageId,
          options.sectionId,
          options.columnGap,
          guide?.version,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(activeGuideId);
          optimisticallyEditSectionAppearance(
            activeGuideId,
            activePageId,
            variables.sectionId,
            {
              columnSpacing: variables.columnGap,
            },
          );
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
    addSectionTemplate: useMutation(
      (mutationOptions: {
        sectionId: string;
        sectionTemplate: SectionTemplatesEnum;
        sectionPosition: number;
      }) => {
        return addSectionTemplate(
          activeGuideId,
          TemplateBatchCommands(
            activeGuideId,
            activeChapterId,
            activePageId,
            mutationOptions.sectionId,
            guide?.version,
            mutationOptions.sectionTemplate,
            mutationOptions.sectionPosition,
          ),
          guide?.version,
        );
      },
      {
        onMutate: () => {
          updateGuideVersion(activeGuideId);
        },
        onSuccess: data => {
          handleMutationSuccess(null, data, {
            entityId: activeGuideId,
          });
        },
      },
    ),
  };
};

const optimisticallyAddNewSection = (
  guideId: string,
  pageId: string,
  newSectionId: string,
  sectionPosition: number,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const newSection: Section = {
        id: newSectionId,
        columns: [{ widgets: [] }],
        appearance: {
          height: DEFAULT_SECTION_HEIGHT,
          width: DEFAULT_SECTION_WIDTH,
          contentAlignment: ContentAlignment.TOP,
          contentSpacing: DEFAULT_SECTION_CONTENT_SPACING,
          columnSpacing: DEFAULT_SECTION_COLUMN_GAP,
          paddingBottom: DEFAULT_SECTION_VERTICAL_PADDING,
          paddingTop: DEFAULT_SECTION_VERTICAL_PADDING,
          paddingRight: DEFAULT_SECTION_HORIZONTAL_PADDING,
          paddingLeft: DEFAULT_SECTION_HORIZONTAL_PADDING,
          style: '',
        },
        sectionLayout: SectionLayout.SINGLE,
        backgroundColorId: '',
        asset: {
          id: '',
          name: '',
          imageUrl: '',
          detailUrl: '',
        },

        style: emptyCSSObject,
      };

      return produce(old, draft => {
        draft.sections.splice(sectionPosition, 0, newSection);
      });
    },
  );
};

const optimisticallyDeleteSection = (
  guideId: string,
  pageId: string,
  sectionId: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      return produce(old, draft => {
        remove(draft.sections, section => section.id === sectionId);
      });
    },
  );
};

const optimisticallyChangeSectionLayout = (
  guideId: string,
  pageId: string,
  sectionId: string,
  sectionLayout: SectionLayout,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      return produce(old, draft => {
        draft.sections[sectionIndex].sectionLayout = sectionLayout;
        draft.sections[sectionIndex].columns = parseColumns(
          old.sections[sectionIndex].columns,
          SectionLayoutNumberOfColumns[sectionLayout],
        );
      });
    },
  );
};

const optimisticallyChangeSectionBackgroundColor = (
  guideId: string,
  pageId: string,
  sectionId: string,
  colorId: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );
      old.sections[sectionIndex].backgroundColorId = colorId;

      return produce(old, draft => {
        draft.sections[sectionIndex].backgroundColorId = colorId;
      });
    },
  );
};
const optimisticallyUpdateSectionBackground = (
  guideId: string,
  pageId: string,
  sectionId: string,
  asset: SectionBackground,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      return produce(old, draft => {
        draft.sections[sectionIndex].asset = { ...asset };
      });
    },
  );
};
const optimisticallyRemoveSectionBackground = (
  guideId: string,
  pageId: string,
  sectionId: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      return produce(old, draft => {
        draft.sections[sectionIndex].asset = {
          id: '',
          name: '',
          imageUrl: '',
          detailUrl: '',
        };
      });
    },
  );
};

const optimisticallyEditSectionAppearance = (
  guideId: string,
  pageId: string,
  sectionId: string,
  parameter: NonNullable<unknown>,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const appearance = old.sections[sectionIndex].appearance;

      return produce(old, draft => {
        draft.sections[sectionIndex].appearance = {
          ...appearance,
          ...parameter,
        };
      });
    },
  );
};

const optimisticallyMoveSection = (
  guideId: string,
  pageId: string,
  to: ToSectionPayload,
  from: number,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      return produce(old, draft => {
        const element = draft.sections.splice(from, 1)[0];
        draft.sections.splice(to.sectionPosition, 0, element);
      });
    },
  );
};

const optimisticallyUpdateSectionStyle = (
  guideId: string,
  pageId: string,
  sectionId: string,
  style: string,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      return produce(old, draft => {
        draft.sections[sectionIndex].style.stylesheet = style;
      });
    },
  );
};

const parseColumns = (
  oldColumns: Column[],
  newColumnsCount: number,
): Column[] => {
  const newColumn: Column = { widgets: [] };

  if (oldColumns.length === newColumnsCount) {
    return oldColumns;
  }

  if (oldColumns.length < newColumnsCount) {
    const newColumns = Array(newColumnsCount - oldColumns.length).fill(
      newColumn,
    );

    return oldColumns.concat(newColumns);
  }

  const newLastColumn = oldColumns.slice(newColumnsCount - 1).reduce(
    (acc: Column, column: Column) => ({
      widgets: [...acc.widgets, ...column.widgets],
    }),
    newColumn,
  );

  return oldColumns.slice(0, newColumnsCount - 1).concat(newLastColumn);
};

enum SectionLayoutNumberOfColumns {
  SINGLE = 1,
  DOUBLE_50_50 = 2,
  DOUBLE_66_33 = 2,
  DOUBLE_33_66 = 2,
  TRIPLE_33_33_33 = 3,
}

export const generateDuplicatedSection = (
  guideId: string,
  pageId: string,
  sectionId: string,
) => {
  const page: Page = queryClient.getQueryData([
    GUIDE_KEYS.PAGE_BY_ID,
    guideId,
    pageId,
  ]);
  const sectionIndex = findIndex(
    page?.sections,
    section => section.id === sectionId,
  );
  const section = page.sections[sectionIndex];
  const widgetMutations: WidgetMutationsPayload[] = [];
  const textBlockMutations: TextBlockMutationPayload[] = [];
  const duplicatedWidgets: WidgetTypes[] = [];

  const duplicatedSection = createDraft(section);
  duplicatedSection.id = generateUUID();

  const widgets = section.columns.flatMap(column => column.widgets);

  widgets.forEach((widget: WidgetTypes) => {
    // We need to send the old -> new ids mutation to the backend with this format
    const mutationIds = {
      from: widget.id,
      to: generateUUID(),
    };

    // We need to duplicate the content of the widgets with the new id
    const newWidget = { ...widget, id: mutationIds.to };
    widgetMutations.push(mutationIds);

    // new keys need to be created when duplicating textWidgets
    if (newWidget.type === WIDGET_TYPE.TEXT) {
      let newTextWidget: TextWidget = newWidget;
      const { blocks } = newTextWidget.text;

      const newTextBlocks = blocks.map(block => {
        // create new key
        const newKey = generateRandomTextBlockKey();
        textBlockMutations.push({
          from: { key: block.key },
          to: { key: newKey },
        });

        // create block with the new key
        return { ...block, key: newKey };
      });

      // update keys in the new Widget
      newTextWidget = {
        ...newWidget,
        text: { ...newTextWidget.text, blocks: newTextBlocks },
      };
      duplicatedWidgets.push(newTextWidget);
    } else {
      duplicatedWidgets.push(newWidget);
    }

    // The duplicated section needs to update also the new ids of the widgets
    duplicatedSection.columns.forEach(column => {
      const index = findIndex(
        column.widgets,
        wgt => wgt.id === mutationIds.from,
      );

      if (index >= 0) {
        column.widgets[index].id = mutationIds.to;
      }
    });
  });

  return { widgetMutations, textBlockMutations, duplicatedSection };
};
