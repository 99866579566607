/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { AxiosError, AxiosResponse, Canceler } from 'axios';
import { API_ROUTES } from '../../constants/app';
import {
  CommandImageWidget,
  CommandTableWidgetCell,
  CommandTextWidget,
  CommandTitleWidget,
  CommandVideoWidget,
  Cover,
  OverviewOrder,
  WIDGET_TYPE,
  ToSectionPayload,
  SectionLayout,
  NewSectionPayload,
  GroupCover,
  GuidesResponse,
  GroupsResponse,
  Group,
  Page,
  Guide,
  GuideLimit,
  ContentAlignment,
  GuideType,
} from '../../types';
import { Command } from '../../types/commands';
import axios, { handleUnauthorized, maxAttempts } from '../axios';
import { PageHeaderHeights } from '../../types/headerHeights';
import {
  getEmptyEditorState,
  getNewWidgetByType,
  prefixKeys,
} from '../../components/widgets/helpers';
import { emptyCSSObject } from '../../constants/widgets';

const CancelToken = Axios.CancelToken;
let cancelFetchGuides: Canceler;
let cancelSearchGuides: Canceler;
let cancelSearchGroups: Canceler;
let cancelFetchGroups: Canceler;

export async function fetchGuides(
  order: OverviewOrder,
  isPublic: boolean,
): Promise<GuidesResponse> {
  if (cancelFetchGuides) {
    cancelFetchGuides();
  }

  return (await axios())
    .get(API_ROUTES.GUIDES_READ(isPublic), {
      cancelToken: new CancelToken((c: Canceler) => {
        cancelFetchGuides = c;
      }),
      params: order,
    })
    .then(res => res.data)
    .catch(thrown => {
      if (Axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      }
    });
}

export async function searchGuides(
  order: OverviewOrder,
): Promise<GuidesResponse> {
  if (cancelSearchGuides) {
    cancelSearchGuides();
  }

  return (await axios())
    .get(API_ROUTES.GUIDES_SEARCH, {
      cancelToken: new CancelToken((c: Canceler) => {
        cancelSearchGuides = c;
      }),
      params: order,
    })
    .then(res => res.data)
    .catch(thrown => {
      if (Axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      }
    });
}

export async function fetchGuideByIdApi(
  guideId: string,
  isPublic: boolean,
): Promise<Guide> {
  return (await axios())
    .get(API_ROUTES.GUIDE_BY_ID_READ_NEW(guideId, isPublic))
    .then(res => res.data)
    .catch((error: AxiosError) => {
      throw error;
    });
}
export async function fetchGuideLimitById(
  guideId: string,
): Promise<GuideLimit> {
  return (await axios())
    .get(API_ROUTES.GUIDE_LIMIT_BY_ID(guideId))
    .then(res => res.data)
    .catch((error: AxiosError) => {
      throw error;
    });
}

export async function fetchPageByIdApi(
  guideId: string,
  pageId: string,
  isPublic: boolean,
): Promise<Page> {
  return (await axios())
    .get(API_ROUTES.PAGE_BY_ID(guideId, pageId, isPublic))
    .then(res => res.data)
    .catch(e => {
      throw e.response.status;
    });
}

export async function createGuide(
  guideId: string,
  guideTitle: string,
  guideState: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.CREATE_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
        guideVersion,
        guideState,
      },
    })
    .catch(error => handleUnauthorized(error, createGuide, ...arguments));
}

export async function createGuideWithTemplateApi(
  guideId: string,
  guideTitle: string,
  chapterId: string,
  chapterTitle: string,
  pageId: string,
  pageTitle: string,
  sectionId: string,
  guideVersion: number,
  guideType: GuideType,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.CREATE_GUIDE_WITH_TEMPLATE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
        chapterId,
        chapterTitle,
        pageId,
        pageTitle,
        sectionId,
        guideType,
      },
    })
    .catch(error =>
      handleUnauthorized(error, createGuideWithTemplateApi, ...arguments),
    );
}

export async function createGuideWithTemplateInsideGroupApi(
  guideId: string,
  guideTitle: string,
  chapterId: string,
  chapterTitle: string,
  pageId: string,
  pageTitle: string,
  sectionId: string,
  groupId: string,
  groupVersion: number,
  guideVersion: number,
  guideType: GuideType,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.CREATE_GUIDE_WITH_TEMPLATE_INSIDE_GROUP,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
        chapterId,
        chapterTitle,
        pageId,
        pageTitle,
        sectionId,
        groupId,
        groupVersion,
        guideType,
      },
    })
    .catch(error =>
      handleUnauthorized(
        error,
        createGuideWithTemplateInsideGroupApi,
        ...arguments,
      ),
    );
}

export async function updateGuideTitle(
  guideId: string,
  guideTitle: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_GUIDE_TITLE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
      },
    })
    .catch(error => handleUnauthorized(error, updateGuideTitle, ...arguments));
}

export async function deleteGuide(
  guideId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {},
    })
    .catch(error => handleUnauthorized(error, deleteGuide, ...arguments));
}

export async function deleteGuideInsideGroup(
  groupId: string,
  groupVersion: number,
  guideId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_GUIDE_INSIDE_GROUP,
      aggregateId: groupId,
      aggregateVersion: groupVersion,
      payload: {
        guideId,
        guideVersion,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deleteGuideInsideGroup, ...arguments),
    );
}

export async function setGuideCover(
  guideId: string,
  guideCover: Cover,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.SET_GUIDE_COVER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideCoverAssetId: guideCover.assetId,
        guideCoverAssetName: guideCover.assetName,
        guideCoverImageUrl: guideCover.imageUrl,
        guideCoverDetailUrl: guideCover.detailUrl,
      },
    })
    .catch(error => handleUnauthorized(error, setGuideCover, ...arguments));
}

export async function removeGuideCover(
  guideId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_GUIDE_COVER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {},
    })
    .catch(error => handleUnauthorized(error, removeGuideCover, ...arguments));
}

export async function addNewTextWidgetToSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.TEXT,
  columnIndex: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const emptyEditorState = JSON.stringify(getEmptyEditorState());

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_TEXT_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        columnIndex,
        widgetText: emptyEditorState,
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewTextWidgetToSection, ...arguments),
    );
}

export async function addNewImageWidgetToSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.IMAGE,
  columnIndex: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const emptyWidgetState = prefixKeys(getNewWidgetByType(widgetType));

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_IMAGE_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        columnIndex,
        ...emptyWidgetState,
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewImageWidgetToSection, ...arguments),
    );
}

export async function addNewVideoWidgetToSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.VIDEO,
  columnIndex: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const emptyWidgetState = prefixKeys(getNewWidgetByType(widgetType));

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_VIDEO_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        columnIndex,
        ...emptyWidgetState,
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewVideoWidgetToSection, ...arguments),
    );
}

export async function editTitleInTitleWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.TITLE,
  content: CommandTitleWidget,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const serializedContent = JSON.stringify(content.widgetTitle);

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_TITLE_IN_TITLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        widgetTitle: serializedContent,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editTitleInTitleWidget, ...arguments),
    );
}

export async function editTextInTextWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.TEXT,
  content: CommandTextWidget,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const serializedContent = JSON.stringify(content.widgetText);

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_TEXT_IN_TEXT_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        widgetText: serializedContent,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editTextInTextWidget, ...arguments),
    );
}

export async function placeAssetInImageWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.IMAGE,
  content: CommandImageWidget,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.PLACE_ASSET_IN_IMAGE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        ...content,
      },
    })
    .catch(error =>
      handleUnauthorized(error, placeAssetInImageWidget, ...arguments),
    );
}

export async function placeAssetInVideoWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.VIDEO,
  content: CommandVideoWidget,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.PLACE_ASSET_IN_VIDEO_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        ...content,
      },
    })
    .catch(error =>
      handleUnauthorized(error, placeAssetInVideoWidget, ...arguments),
    );
}

export async function toggleVideoWidgetSettings({
  guideId,
  chapterId,
  pageId,
  sectionId,
  columnIndex,
  widgetId,
  widgetType,
  content,
  guideVersion,
  command,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  columnIndex: number;
  widgetId: string;
  widgetType: WIDGET_TYPE.VIDEO;
  content: { [key: string]: boolean };
  guideVersion: number;
  command: Command;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetType,
        widgetId,
        ...content,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deleteWidgetFromSection, ...arguments),
    );
}

export async function deleteWidgetFromSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_WIDGET_FROM_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deleteWidgetFromSection, ...arguments),
    );
}

export async function addChapterToGuide(
  guideId: string,
  chapterId: string,
  chapterTitle: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_CHAPTER_TO_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        chapterTitle,
      },
    })
    .catch(error => handleUnauthorized(error, addChapterToGuide, ...arguments));
}

export async function moveChapter(
  guideId: string,
  chapterId: string,
  from: {
    position: number;
  },
  to: {
    position: number;
  },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_CHAPTER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        from,
        to,
      },
    })
    .catch(error => handleUnauthorized(error, moveChapter, ...arguments));
}

export async function deleteChapterFromGuide(
  guideId: string,
  chapterId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_CHAPTER_FROM_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deleteChapterFromGuide, ...arguments),
    );
}

export async function editChapterTitle(
  guideId: string,
  chapterId: string,
  chapterTitle: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CHAPTER_TITLE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        chapterTitle,
      },
    })
    .catch(error => handleUnauthorized(error, editChapterTitle, ...arguments));
}

export async function addPageToChapter(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageTitle: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_PAGE_TO_CHAPTER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        pageTitle,
      },
    })
    .catch(error => handleUnauthorized(error, addPageToChapter, ...arguments));
}

export async function deletePageFromChapter(
  guideId: string,
  chapterId: string,
  pageId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_PAGE_FROM_CHAPTER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deletePageFromChapter, ...arguments),
    );
}

export async function editPageTitle(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageTitle: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_PAGE_TITLE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        pageTitle,
      },
    })
    .catch(error => handleUnauthorized(error, editPageTitle, ...arguments));
}

export async function addSectionToPage(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionPosition: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_SECTION_TO_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        sectionPosition,
      },
    })
    .catch(error => handleUnauthorized(error, addSectionToPage, ...arguments));
}

export async function addSectionTemplate(
  guideId: string,
  batchCommands: object[],
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.BATCH_WRITE, {
      command: Command.EXECUTE_GUIDE_BATCH,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: batchCommands,
    })
    .catch(error =>
      handleUnauthorized(error, addSectionTemplate, ...arguments),
    );
}

export async function deleteSectionFromPage(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DELETE_SECTION_FROM_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, deleteSectionFromPage, ...arguments),
    );
}

export async function changeSectionLayout(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionLayout: SectionLayout,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.CHANGE_SECTION_LAYOUT,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        sectionLayout,
      },
    })
    .catch(error =>
      handleUnauthorized(error, changeSectionLayout, ...arguments),
    );
}

export async function moveWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  columnIndex: number,
  to: {
    sectionId: string;
    columnIndex: number;
    position: number;
  },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        columnIndex,
        to,
      },
    })
    .catch(error => handleUnauthorized(error, moveWidget, ...arguments));
}

export async function movePage(
  guideId: string,
  pageId: string,
  from: {
    chapterId: string;
  },
  to: {
    chapterId: string;
    position: number;
  },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        pageId,
        from,
        to,
      },
    })
    .catch(error => handleUnauthorized(error, movePage, ...arguments));
}

export async function publishPage(
  guideId: string,
  chapterId: string,
  pageId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.PUBLISH_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
      },
    })
    .catch(error => handleUnauthorized(error, publishPage, ...arguments));
}

export async function unpublishPage(
  guideId: string,
  chapterId: string,
  pageId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.UNPUBLISH_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
      },
    })
    .catch(error => handleUnauthorized(error, unpublishPage, ...arguments));
}

export async function placeAssetInPageHeader(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageCover: Cover,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.PLACE_ASSET_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
        pageHeaderAssetPayload: pageCover,
      },
    })
    .catch(error =>
      handleUnauthorized(error, placeAssetInPageHeader, ...arguments),
    );
}

export async function editHeightInPageHeader(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageHeaderHeight: PageHeaderHeights,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_HEIGHT_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        pageHeaderHeight,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editHeightInPageHeader, ...arguments),
    );
}

export async function removeAssetFromPageHeader(
  guideId: string,
  chapterId: string,
  pageId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_ASSET_FROM_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, removeAssetFromPageHeader, ...arguments),
    );
}

export async function editBackgroundColorInPageHeader(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageHeaderBackgroundColorId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_BACKGROUND_COLOR_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
        pageHeaderBackgroundColorId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editBackgroundColorInPageHeader, ...arguments),
    );
}
export async function editTextColorInPageHeader(
  guideId: string,
  chapterId: string,
  pageId: string,
  pageHeaderTextColorId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_TEXT_COLOR_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideId,
        chapterId,
        pageId,
        pageHeaderTextColorId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editTextColorInPageHeader, ...arguments),
    );
}

export async function addNewTableWidgetToSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.TABLE,
  columnIndex: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const emptyWidgetState = prefixKeys(getNewWidgetByType(widgetType));

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_TABLE_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        widgetType,
        columnIndex,
        ...emptyWidgetState,
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewTableWidgetToSection, ...arguments),
    );
}

export async function editCellInTableWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnIndex: number,
  widgetId: string,
  widgetCellContentPayloads: CommandTableWidgetCell[],
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CELLS_CONTENT_IN_TABLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex: sectionColumnIndex,
        widgetId,
        widgetCellContentPayloads: widgetCellContentPayloads.map(p => ({
          ...p,
          content: JSON.stringify(p.content),
        })),
      },
    })
    .catch(error =>
      handleUnauthorized(error, editCellInTableWidget, ...arguments),
    );
}

export async function insertRowInTableWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnIndex: number,
  widgetId: string,
  widgetInsertRowPayload: { rowIndex: number },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.INSERT_ROW_IN_TABLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex: sectionColumnIndex,
        widgetId,
        widgetInsertRowPayload,
      },
    })
    .catch(error =>
      handleUnauthorized(error, insertRowInTableWidget, ...arguments),
    );
}

export async function insertColumnInTableWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnIndex: number,
  widgetId: string,
  widgetInsertColumnPayload: { columnIndex: number },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.INSERT_COLUMN_IN_TABLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex: sectionColumnIndex,
        widgetId,
        widgetInsertColumnPayload,
      },
    })
    .catch(error =>
      handleUnauthorized(error, insertColumnInTableWidget, ...arguments),
    );
}

export async function removeColumnFromTableWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnIndex: number,
  widgetId: string,
  widgetRemoveColumnPayload: { columnIndex: number },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_COLUMN_FROM_TABLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex: sectionColumnIndex,
        widgetId,
        widgetRemoveColumnPayload,
      },
    })
    .catch(error =>
      handleUnauthorized(error, removeColumnFromTableWidget, ...arguments),
    );
}

export async function removeRowFromTableWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnIndex: number,
  widgetId: string,
  widgetRemoveRowPayload: { rowIndex: number },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_ROW_FROM_TABLE_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex: sectionColumnIndex,
        widgetId,
        widgetRemoveRowPayload,
      },
    })
    .catch(error =>
      handleUnauthorized(error, removeRowFromTableWidget, ...arguments),
    );
}

export async function addNewAudioWidgetToSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  widgetType: WIDGET_TYPE.AUDIO,
  columnIndex: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  const emptyWidgetState = prefixKeys(getNewWidgetByType(widgetType));

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_AUDIO_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        columnIndex,
        ...emptyWidgetState,
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewAudioWidgetToSection, ...arguments),
    );
}

export async function placeAssetInAudioWidget(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  _widgetType: WIDGET_TYPE.AUDIO,
  content: {
    columnIndex: number;
    widgetAudioPayload: {
      assetId: string;
      assetName: string;
      audioUrl: string;
      detailUrl: string;
    };
  },
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.PLACE_ASSET_IN_AUDIO_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        ...content,
      },
    })
    .catch(error =>
      handleUnauthorized(error, placeAssetInAudioWidget, ...arguments),
    );
}

export async function featureGuide(
  guideId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.FEATURE_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {},
    })
    .catch(error => handleUnauthorized(error, featureGuide, ...arguments));
}

export async function unFeatureGuide(
  guideId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.UNFEATURE_GUIDE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {},
    })
    .catch(error => handleUnauthorized(error, featureGuide, ...arguments));
}

export async function moveSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  to: ToSectionPayload,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        to,
      },
    })
    .catch(error => handleUnauthorized(error, moveSection, ...arguments));
}

export async function duplicateSectionInPage(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  newSectionPayload: NewSectionPayload,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DUPLICATE_SECTION_IN_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        newSectionPayload,
      },
    })
    .catch(error =>
      handleUnauthorized(error, duplicateSectionInPage, ...arguments),
    );
}

export const editSectionWidthApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionWidth: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_WIDTH,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionWidth,
    },
  });
};
export const editSectionHeightApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionHeight: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_HEIGHT,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionHeight,
    },
  });
};
export const editSectionVerticalPaddingApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionVerticalPadding: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_VERTICAL_PADDING,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionVerticalPadding,
    },
  });
};
export const editSectionHorizontalPaddingApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionHorizontalPadding: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_HORIZONTAL_PADDING,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionHorizontalPadding,
    },
  });
};
export const changeSectionAlignmentApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionContentAlignment: ContentAlignment,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_CONTENT_ALIGNMENT,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionContentAlignment,
    },
  });
};
export const editSectionContentSpacingApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionContentSpacing: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_CONTENT_SPACING,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionContentSpacing,
    },
  });
};
export const editSectionColumnGapApi = async (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionColumnSpacing: number,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> => {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.EDIT_SECTION_COLUMN_SPACING,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionColumnSpacing,
    },
  });
};
export async function editSectionBackgroundImage({
  guideId,
  chapterId,
  pageId,
  sectionId,
  guideVersion,
  cover,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  guideVersion: number;
  cover: Cover;
}) {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.PLACE_ASSET_IN_SECTION,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
      sectionPayload: {
        asset: {
          id: cover.assetId,
          name: cover.assetName,
          imageUrl: cover.imageUrl,
          detailUrl: cover.detailUrl,
          extension: cover.extension,
        },
      },
    },
  });
}
export async function removeSectionBackgroundImage({
  guideId,
  chapterId,
  pageId,
  sectionId,
  guideVersion,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  guideVersion: number;
}) {
  return (await axios()).post(API_ROUTES.GUIDES_WRITE, {
    command: Command.REMOVE_ASSET_FROM_SECTION,
    aggregateId: guideId,
    aggregateVersion: guideVersion,
    payload: {
      chapterId,
      pageId,
      sectionId,
    },
  });
}
export async function editBackgroundColorInSection(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  sectionBackgroundColorId: string,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_BACKGROUND_COLOR_IN_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        sectionBackgroundColorId,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editBackgroundColorInSection, ...arguments),
    );
}

export async function editSectionStyleApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  guideVersion,
  style,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  guideVersion: number;
  style: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_STYLE_IN_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        sectionPayload: {
          style: {
            ...emptyCSSObject,
            stylesheet: style,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editSectionStyleApi, ...arguments),
    );
}
export async function editTableOfContents(
  guideId: string,
  chapterId: string,
  pageId: string,
  isVisibleInPage: boolean,
  isVisibleInGuide: boolean,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_TABLE_OF_CONTENTS,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        isVisibleInPage,
        isVisibleInGuide,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editTableOfContents, ...arguments),
    );
}

export async function createGroup(
  groupId: string,
  groupVersion: number,
  groupTitle: string,
  groupDescription: string,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.CREATE_GROUP,
      aggregateId: groupId,
      aggregateVersion: groupVersion,
      payload: {
        groupTitle,
        groupDescription,
      },
    })
    .catch(error => handleUnauthorized(error, createGroup, ...arguments));
}

export async function fetchGroups(
  order: OverviewOrder,
): Promise<GroupsResponse> {
  if (cancelFetchGroups) {
    cancelFetchGroups();
  }

  return (await axios())
    .get(API_ROUTES.GROUPS_READ, {
      cancelToken: new CancelToken((c: Canceler) => {
        cancelFetchGroups = c;
      }),
      params: order,
    })
    .then(res => res.data)
    .catch(thrown => {
      if (Axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      }
    });
}

export async function searchGroups(
  order: OverviewOrder,
): Promise<GroupsResponse> {
  if (cancelSearchGroups) {
    cancelSearchGroups();
  }

  return (await axios())
    .get(API_ROUTES.GROUPS_SEARCH, {
      cancelToken: new CancelToken((c: Canceler) => {
        cancelSearchGroups = c;
      }),
      params: order,
    })
    .then(res => res.data)
    .catch(thrown => {
      if (Axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      }
    });
}

export async function fetchGroupById(
  groupId: string,
  order: OverviewOrder,
): Promise<AxiosResponse> {
  return (await axios())
    .get(API_ROUTES.GROUP_BY_ID_READ(groupId), { params: order })

    .catch(error => {
      return handleUnauthorized(error, fetchGroupById, ...arguments);
    });
}
export async function fetchGroupByIdApi(
  groupId: string,
  order: OverviewOrder,
): Promise<Group> {
  return (await axios())
    .get(API_ROUTES.GROUP_BY_ID_READ(groupId), { params: order })
    .then(res => res.data)
    .catch(error => {
      return handleUnauthorized(error, fetchGroupById, ...arguments);
    });
}

export async function addGuideToGroup(
  aggregateId: string,
  guideId: string,
  aggregateVersion: number,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.ADD_GUIDE_TO_GROUP,
      aggregateId,
      aggregateVersion,
      payload: { guideId },
    })
    .catch(error => {
      return handleUnauthorized(error, addGuideToGroup, ...arguments);
    });
}
export async function editGroupTitle(
  aggregateId: string,
  aggregateVersion: number,
  groupTitle: string,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.EDIT_GROUP_TITLE,
      aggregateId,
      aggregateVersion,
      payload: { groupTitle },
    })
    .catch(error => {
      return handleUnauthorized(error, editGroupTitle, ...arguments);
    });
}
export async function editGroupDescription(
  aggregateId: string,
  aggregateVersion: number,
  groupDescription: string,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.EDIT_GROUP_DESCRIPTION,
      aggregateId,
      aggregateVersion,
      payload: { groupDescription },
    })
    .catch(error => {
      return handleUnauthorized(error, editGroupDescription, ...arguments);
    });
}
export async function editGroupCoverAsset(
  aggregateId: string,
  aggregateVersion: number,
  groupCover: GroupCover,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.EDIT_GROUP_COVER_ASSET,
      aggregateId,
      aggregateVersion,
      payload: {
        groupCoverAssetId: groupCover.assetId,
        groupCoverAssetName: groupCover.assetName,
        groupCoverImageUrl: groupCover.imageUrl,
        groupCoverDetailUrl: groupCover.detailUrl,
      },
    })
    .catch(error => {
      return handleUnauthorized(error, editGroupCoverAsset, ...arguments);
    });
}
export async function editGroupCoverColor(
  aggregateId: string,
  aggregateVersion: number,
  groupCoverColorId: string,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.EDIT_GROUP_COVER_COLOR,
      aggregateId,
      aggregateVersion,
      payload: { groupCoverColorId },
    })
    .catch(error => {
      return handleUnauthorized(error, editGroupCoverColor, ...arguments);
    });
}

export async function deleteGuideFromGroup(
  aggregateId: string,
  aggregateVersion: number,
  guideId: string,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.DELETE_GUIDE_FROM_GROUP,
      aggregateId,
      aggregateVersion,
      payload: { guideId },
    })
    .catch(error => {
      return handleUnauthorized(error, deleteGuideFromGroup, ...arguments);
    });
}

export async function deleteGroup(
  groupId: string,
  groupVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GROUPS_WRITE, {
      command: Command.DELETE_GROUP,
      aggregateId: groupId,
      aggregateVersion: groupVersion,
    })
    .catch(error => handleUnauthorized(error, deleteGroup, ...arguments));
}

export async function setImageAction(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  columnIndex: string,
  commandType: string,
  imageActionUrl: string,
  imageActionNewTab: boolean,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: commandType,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        columnIndex,
        imageActionUrl,
        imageActionNewTab,
      },
    })
    .catch(error => handleUnauthorized(error, setImageAction, ...arguments));
}
export async function setImageActionApi(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  widgetId: string,
  columnIndex: number,
  commandType: Command,
  guideVersion: number,
  imageActionUrl?: string,
  imageActionNewTab?: boolean,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: commandType,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        widgetId,
        columnIndex,
        imageActionUrl,
        imageActionNewTab,
      },
    })
    .catch(error => handleUnauthorized(error, setImageAction, ...arguments));
}

export async function createGuideInsideGroupApi(
  guideId: string,
  guideVersion: number,
  guideTitle: string,
  groupId: string,
  groupVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.CREATE_GUIDE_INSIDE_GROUP,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        guideTitle,
        groupId,
        groupVersion,
      },
    })
    .catch(error =>
      handleUnauthorized(error, createGuideInsideGroupApi, ...arguments),
    );
}

export async function duplicateGuideApi(
  aggregateId: string,
  aggregateVersion: number,
  payload: {
    sourceGuideId: string;
    sourceGuideVersion: number;
    guideTitle: string;
  },
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DUPLICATE_GUIDE,
      aggregateId,
      aggregateVersion,
      payload,
    })
    .catch(error => handleUnauthorized(error, duplicateGuideApi, ...arguments));
}

export async function duplicateGuideInsideGroupApi(
  aggregateId: string,
  aggregateVersion: number,
  payload: {
    guideTitle: string;
    sourceGuideId: string;
    sourceGuideVersion: number;
    groupId: string;
    groupVersion: number;
  },
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.DUPLICATE_GUIDE_INSIDE_GROUP,
      aggregateId,
      aggregateVersion,
      payload,
    })
    .catch(error =>
      handleUnauthorized(error, duplicateGuideInsideGroupApi, ...arguments),
    );
}

export async function movePageToGuideApi(
  aggregateId: string,
  aggregateVersion: number,
  payload: {
    deletePage: boolean;
    from: {
      guideId: string;
      guideVersion: number;
      chapterId: string;
      pageId: string;
    };
    to: {
      chapterId: string;
      pageId: string;
    };
  },
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_PAGE_TO_GUIDE,
      aggregateId,
      aggregateVersion,
      payload,
    })
    .catch(error =>
      handleUnauthorized(error, movePageToGuideApi, ...arguments),
    );
}

export async function editPageTitleVisibility(
  guideId: string,
  chapterId: string,
  pageId: string,
  isVisible: boolean,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_TITLE_VISIBILITY_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        isPageHeaderTitleVisible: isVisible,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editPageTitleVisibility, ...arguments),
    );
}

export async function editChapterTitleVisibility(
  guideId: string,
  chapterId: string,
  pageId: string,
  isVisible: boolean,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_SUBTITLE_VISIBILITY_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        isPageHeaderSubtitleVisible: isVisible,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editChapterTitleVisibility, ...arguments),
    );
}

export async function editPageHeaderVisibility(
  guideId: string,
  chapterId: string,
  pageId: string,
  isVisible: boolean,
  guideVersion: number,
  _n: number = maxAttempts,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_HEADER_VISIBILITY_IN_PAGE_HEADER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        isPageHeaderVisible: isVisible,
      },
    })
    .catch(error =>
      handleUnauthorized(error, editPageHeaderVisibility, ...arguments),
    );
}
