import { css, keyframes } from 'styled-components';
import { token } from '@bynder/design-system';
import { Color } from '../../types/color';
import { isDevelopment } from '../app';

const PYBYNDER_HEADER_HEIGHT = '70px';
export const GUIDELINES_HEADER_HEIGHT = '72px';

export const COLOR = {
  WHITE_00: `rgba(${token['white-rgb']} / .0)`,
  WHITE_10: `rgba(${token['white-rgb']} / .1)`,
  WHITE_20: `rgba(${token['white-rgb']} / .2)`,
  WHITE_50: `rgba(${token['white-rgb']} / .5)`,
  WHITE_80: `rgba(${token['white-rgb']} / .8)`,
  SHADOW: `${token.elevation2}, ${token.elevationStatic}`,
};

export const TEXT = {
  FONT_FAMILY: "'Source Sans Pro', arial",
  FONT_WEIGHT: 400,
  ALPHA_TEXT_SIZE: '2rem',
  DEFAULT_FONT_FAMILY: 'Source Sans Pro',
  DEFAULT_FONT_NAME: 'SourceSansPro-regular',
  DEFAULT_WEIGHT: 400,
  FONT_SIZE_TITLE: '1.85em',
  FONT_SIZE_SUBTITLE: '1.250em',
  FONT_SIZE_PARAGRAPH: '1em',
  FONT_SIZE_CAPTION: '0.87em',
};

export const VISUALS = {
  PADDING_XXXL: '56px',
  MARGIN_XXXL: '56px',
  ZINDEX_FLOATING_MENU: 10,
  ZINDEX_EDITOR_TOOLBAR: 100,
  ZINDEX_TOPBAR_FIXED: 1010,
  ZINDEX_HEADER_FIXED: 1020,
  ZINDEX_OVERLAY: 1030,
  ZINDEX_MODAL_WRAPPER: 1031,
  ZINDEX_MODAL: 1070,
  BYNDER_HEADER_HEIGHT: isDevelopment
    ? '0px'
    : `var(--headerHeight, ${PYBYNDER_HEADER_HEIGHT})`,
  HEADER_HEIGHT: isDevelopment
    ? GUIDELINES_HEADER_HEIGHT
    : `calc(var(--headerHeight, ${PYBYNDER_HEADER_HEIGHT}) + ${GUIDELINES_HEADER_HEIGHT})`,
  ICON_SIZE: '30px',
  ICON_SIZE_BIG: '40px',
  CONTAINER_TOP_SPACING: '30px',
  GUIDE_TITLE_HEIGHT: '110px',
  SIDEBAR_WIDTH: '272px',
  THEMESET_HEADER_HEIGHT: '88px',
};

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const skeletonAnimation = css`
  animation: ${pulse} 1.5s linear infinite;
`;

export const scrollShadow = css`
  background:
    linear-gradient(white 0%, ${COLOR.WHITE_00}),
    linear-gradient(${COLOR.WHITE_00}, white 100%) 0 100%,
    linear-gradient(${token.gray25a} 0%, ${COLOR.WHITE_00}),
    linear-gradient(${COLOR.WHITE_00}, ${token.gray25a} 100%) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size:
    100% 40px,
    100% 40px,
    100% 6px,
    100% 6px;

  background-attachment: local, local, scroll, scroll;
`;

export const size = {
  mobileL: 350,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 1990,
  desktopL: 2560,
};

export const device = {
  mobile: `(min-width: ${size.mobileL}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktopL}px)`,
};

export const containerWidth = {
  small: '800px',
  medium: '1100px',
  large: '1280px',
  full: '100%',
};

export const fontSizeRoot = token.fontSize100;

export enum PAGE_HEADER_SIZE {
  DEFAULT = '200px',
  MEDIUM = '400px',
  LARGE = '600px',
}

export const defaultBlack: Color = {
  id: null,
  hexCode: token.gray1000,
  creationTime: '',
  lastModifiedTime: '',
  label: 'Default Black',
};

export enum FontSavingStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
