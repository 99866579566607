import { API_ROUTES, CONFIG, isDevelopment } from '../../constants/app';
import { Typography } from '../../types/typography';
import axios from '../axios';
import {
  GuideProperties,
  NavigationBar,
  PortalInformation,
  Sidebar,
  ThemesetDetails,
  Themeset,
} from '../../types';
import { Color } from '../../types/color';
import { Font } from '../../constants/themesets';

export async function fetchThemesets(): Promise<Themeset[]> {
  return (await axios()).get(CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS);
}

export async function fetchThemesetsApi(): Promise<Themeset[]> {
  return (await axios())
    .get(CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS)
    .then(res => res.data);
}

export async function duplicateThemeset(themesetId: string): Promise<Themeset> {
  return (await axios()).post(
    CONTENT_CUSTOMIZATION_ENDPOINTS.DUPLICATE_THEMESET(themesetId),
  );
}

export async function editTypography(
  themesetId: string,
  typography: Typography,
): Promise<Typography> {
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_TYPOGRAPHY(themesetId),
    typography,
  );
}

export async function editNavBarApi(
  themesetId: string,
  navBarOptions: NavigationBar,
): Promise<NavigationBar> {
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_NAVBAR(themesetId),
    {
      backgroundColorId: navBarOptions.backgroundColor?.id || null,
      borderColorId: navBarOptions.borderColor?.id || null,
      textColorId: navBarOptions.textColor?.id || null,
      fontFamily: navBarOptions.fontFamily,
      fontStyle: navBarOptions.fontStyle,
      fontSize: navBarOptions.fontSize,
    },
  );
}

export async function editSidebarApi(
  themesetId: string,
  sidebarOptions: Sidebar,
): Promise<Sidebar> {
  const { chapter, page, tableOfContents, backgroundColor, borderColor } =
    sidebarOptions;
  const requestObject = {
    backgroundColorId: backgroundColor?.id ?? null,
    borderColorId: borderColor?.id ?? null,
    chapter: {
      fontFamily: chapter.fontFamily,
      fontStyle: chapter.fontStyle,
      fontSize: chapter.fontSize,
      colorIds: {
        text: {
          normal: chapter.colors.text.normal?.id ?? null,
          hover: chapter.colors.text.hover?.id ?? null,
        },
        background: {
          hover: chapter.colors.background.hover?.id ?? null,
        },
      },
    },
    page: {
      fontFamily: page.fontFamily,
      fontStyle: page.fontStyle,
      fontSize: page.fontSize,
      colorIds: {
        text: {
          normal: page.colors.text.normal?.id ?? null,
          hover: page.colors.text.hover?.id ?? null,
          active: page.colors.text.active?.id ?? null,
        },
        background: {
          hover: page.colors.background.hover?.id ?? null,
          active: page.colors.background.active?.id ?? null,
        },
      },
    },
    tableOfContents: {
      fontFamily: tableOfContents.fontFamily,
      fontStyle: tableOfContents.fontStyle,
      fontSize: tableOfContents.fontSize,
      colorIds: {
        text: {
          normal: tableOfContents.colors.text.normal?.id ?? null,
          hover: tableOfContents.colors.text.hover?.id ?? null,
          active: tableOfContents.colors.text.active?.id ?? null,
        },
        background: {
          hover: tableOfContents.colors.background.hover?.id ?? null,
          active: tableOfContents.colors.background.active?.id ?? null,
        },
        accent: {
          normal: tableOfContents.colors.accent.normal?.id ?? null,
          hover: tableOfContents.colors.accent.hover?.id ?? null,
          active: tableOfContents.colors.accent.active?.id ?? null,
        },
      },
    },
  };

  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_SIDEBAR(themesetId),
    requestObject,
  );
}

export async function assignThemesetToGuide(
  themesetId: string,
  guideId: string,
): Promise<Typography> {
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.ASSIGN_THEMESET_TO_GUIDE(
      themesetId,
      guideId,
    ),
  );
}

export async function fetchAssignedThemeset(
  guideId: string,
): Promise<Themeset> {
  return (await axios())
    .get(CONTENT_CUSTOMIZATION_ENDPOINTS.ASSIGNED_THEMESET(guideId))
    .then(res => res.data);
}
export interface ThemesetAssignationType {
  [key: string]: string[];
}
export async function fetchThemesetAssignationApi(): Promise<ThemesetAssignationType> {
  return (await axios())
    .get(CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_ASSIGNATION())
    .then(res => res.data);
}
export async function fetchJSONThemeApi() {
  return (await axios(false))
    .get(CONTENT_CUSTOMIZATION_ENDPOINTS.THEMES)
    .then(res => res.data);
}

export const fetchFontsApi = async (): Promise<Font[]> => {
  return (await axios(false))
    .get(CONTENT_CUSTOMIZATION_ENDPOINTS.FONTS.PUBLIC)
    .then(res => res.data);
};

export async function createThemesetColor(
  themesetId: string,
  colorSetId: string,
  color: Color,
): Promise<Typography> {
  return (await axios()).put(
    CONTENT_CUSTOMIZATION_ENDPOINTS.CREATE_THEMESET_COLOR(
      themesetId,
      colorSetId,
    ),
    {
      id: color.id,
      hexCode: color.hexCode,
      label: color.label,
    },
  );
}

export async function editThemesetColor(
  themesetId: string,
  colorSetId: string,
  color: Color,
): Promise<Typography> {
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.EDIT_THEMESET_COLOR(
      themesetId,
      colorSetId,
      color.id,
    ),
    {
      id: color.id,
      hexCode: color.hexCode,
      label: color.label,
    },
  );
}

export async function deleteThemesetColor(
  themesetId: string,
  colorSetId: string,
  colorId: string,
): Promise<Typography> {
  return (await axios()).delete(
    CONTENT_CUSTOMIZATION_ENDPOINTS.DELETE_THEMESET_COLOR(
      themesetId,
      colorSetId,
      colorId,
    ),
  );
}

export async function editThemesetDetails(
  themesetId: string,
  themesetDetails: ThemesetDetails,
) {
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.EDIT_THEMESET_DETAILS(themesetId),
    themesetDetails,
  );
}

export async function deleteThemeset(themesetId: string) {
  return (await axios()).delete(
    CONTENT_CUSTOMIZATION_ENDPOINTS.DELETE_THEMESET(themesetId),
  );
}

export async function createThemesetApi(
  themesetId: string,
  themesetDetails: ThemesetDetails,
) {
  return (await axios()).put(CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS, {
    id: themesetId,
    ...themesetDetails,
  });
}

export async function editGuidePropertiesApi(
  themesetId: string,
  guidePropertiesOptions: GuideProperties,
): Promise<GuideProperties> {
  const {
    logoId,
    logoUrl,
    landingPageHeaderEnabled,
    pageHeaderEnabled,
    customLogoEnabled,
    backButton,
    backButtonBehavior,
  } = guidePropertiesOptions;
  return (await axios()).patch(
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_PROPERTIES(themesetId),
    {
      logoId,
      logoUrl,
      landingPageHeaderEnabled,
      pageHeaderEnabled,
      customLogoEnabled,
      backButton,
      backButtonBehavior,
    },
  );
}

export async function fetchPortalInformationApi(): Promise<PortalInformation> {
  return (await axios())
    .get(API_ROUTES.PORTAL_INFORMATION)
    .then(res => res.data);
}

export const CONTENT_CUSTOMIZATION_ENDPOINTS = {
  FONTS: {
    PRIVATE: '/v7/content-customization/v2/fonts',
    PUBLIC: '/v7/public-content-customization/v2/fonts',
  },
  THEMES: '/v7/public-content-customization/v1/themes',
  THEMESETS: isDevelopment
    ? `${CONFIG.CONTENT_CUSTOMIZATION_API}/v1/themesets/`
    : '/v7/public-content-customization/v1/themesets/',
  THEMESET_ASSIGNATION: () =>
    `${CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS}assignments/`,
  THEMESET_BY_ID: (themesetId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS + themesetId,
  DUPLICATE_THEMESET: (themesetId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(themesetId) + '/duplicate',
  THEMESET_TYPOGRAPHY: (themesetId: string) =>
    `${CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(
      themesetId,
    )}/typographies`,
  THEMESET_NAVBAR: (themesetId: string) =>
    `${CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(themesetId)}/navbar`,
  THEMESET_SIDEBAR: (themesetId: string) =>
    `${CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(themesetId)}/sidebar`,
  THEMESET_PROPERTIES: (themesetId: string) =>
    `${CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(themesetId)}/properties`,
  ASSIGN_THEMESET_TO_GUIDE: (themesetId: string, guideId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESET_BY_ID(themesetId) +
    '/assign/guide/' +
    guideId,
  ASSIGNED_THEMESET: (guideId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS + 'assign/guide/' + guideId,
  CREATE_THEMESET_COLOR: (themesetId: string, colorSetId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS +
    themesetId +
    '/colorSets/' +
    colorSetId +
    '/colors',
  EDIT_THEMESET_COLOR: (
    themesetId: string,
    colorSetId: string,
    colorId: string,
  ) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS +
    themesetId +
    '/colorSets/' +
    colorSetId +
    '/colors/' +
    colorId,
  DELETE_THEMESET_COLOR: (
    themesetId: string,
    colorSetId: string,
    colorId: string,
  ) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS +
    themesetId +
    '/colorSets/' +
    colorSetId +
    '/colors/' +
    colorId,
  EDIT_THEMESET_DETAILS: (themesetId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS + themesetId,
  DELETE_THEMESET: (themesetId: string) =>
    CONTENT_CUSTOMIZATION_ENDPOINTS.THEMESETS + themesetId,
};
