import { CVFile, StyleSheet, WIDGET_TYPE } from '../../types';
import { EXTERNAL_ROUTES } from '../app';
import { ContentBlock } from 'draft-js';

export const MAX_LENGTH_TEXT_WIDGET = 20000;
export const MAX_LENGTH_TITLE_WIDGET = 255;

export enum DefaultColorsEnum {
  HEX = 'hex',
  RGB = 'rgb',
  CMYK = 'cmyk',
}

export enum WidgetPosition {
  ONE_COLUMN = 'ONE_COLUMN',
  TWO_COLUMNS_EQUAL = 'TWO_COLUMNS_EQUAL',
  TWO_COLUMNS_SMALL = 'TWO_COLUMNS_SMALL',
  TWO_COLUMNS_LARGE = 'TWO_COLUMNS_LARGE',
  THREE_COLUMNS_EQUAL = 'THREE_COLUMNS_EQUAL',
}

export const defaultColors = [
  {
    key: 'HEX',
    value: '',
  },
  {
    key: 'RGB',
    value: '',
  },
  {
    key: 'CMYK',
    value: '',
  },
];

export enum ImageExtensions {
  jpg = 'jpg',
  png = 'png',
  gif = 'gif',
  bmp = 'bmp',
  tiff = 'tiff',
  tif = 'tif',
  jpeg = 'jpeg',
  webp = 'webp',
  raw = 'raw',
  svg = 'svg',
}

export enum SpacingWidgetOptions {
  None = 'NONE',
  Dashed = 'DASHED',
  Solid = 'SOLID',
}

export const copyShareUrlToClipboard = (entityId: string, pageId?: string) => {
  const getPageUrl = () => EXTERNAL_ROUTES.PAGE(entityId, pageId);
  const getGuideUrl = () => EXTERNAL_ROUTES.GUIDE(entityId);
  const entityUrl = pageId ? getPageUrl() : getGuideUrl();

  return navigator.clipboard.writeText(entityUrl);
};

export const IMAGE_UPLOADER_REQUIRED_PERMISSIONS = [
  'asset_bank.asset.create',
  'asset_bank.asset.publish',
];

export const getAssetUrl = (
  extension: string,
  file: CVFile,
  webImage: string,
) => (extension in ImageExtensions ? file.selectedFile.url : webImage || '');

export enum ImageActions {
  OPEN_PREVIEW = 'OPEN_PREVIEW',
  NO_ACTION = 'NO_ACTION',
  LINK_TO_URL = 'LINK_TO_URL',
}

export const isNoAction = (ImageAction: string) =>
  ImageAction === ImageActions.NO_ACTION;
export const isOpenPreview = (ImageAction: string) =>
  ImageAction === ImageActions.OPEN_PREVIEW;
export const isLinkToUrl = (ImageAction: string) =>
  ImageAction === ImageActions.LINK_TO_URL;

// Mp4 is currently the only file extension accepted by all the browsers
export const ORIGINAL_EXTENSION = '.mp4';
export const PREVIEW_EXTENSION = 'mp4';

export const getVideoPreview = (
  previewUrls: string[],
  previewExtension: string,
  originalFileUrl: string,
) =>
  originalFileUrl?.includes(ORIGINAL_EXTENSION)
    ? originalFileUrl
    : previewUrls?.find(previewUrl => previewUrl.includes(previewExtension));

export const getBlockFirstCharHexColor = (block: ContentBlock) =>
  block.getInlineStyleAt(0).toArray()[0]?.replace('color-', '');

export const MAX_COLUMNS = 10;
export const MAX_ROWS = 100;

export const getWidgetName = (widgetType: WIDGET_TYPE) => {
  switch (widgetType) {
    case WIDGET_TYPE.BUTTON:
      return 'WIDGETS_CONSTANTS.BUTTON';
    case WIDGET_TYPE.AUDIO:
      return 'WIDGETS_CONSTANTS.AUDIO';
    case WIDGET_TYPE.COLLECTION:
      return 'WIDGETS_CONSTANTS.COLLECTION';
    case WIDGET_TYPE.COLOR_PALETTE:
      return 'WIDGETS_CONSTANTS.COLOR_PALETTE';
    case WIDGET_TYPE.IMAGE:
    case WIDGET_TYPE.PERSONAL_FILE:
      return 'WIDGETS_CONSTANTS.MEDIA';
    case WIDGET_TYPE.SPACER:
      return 'WIDGETS_CONSTANTS.SPACING_DIVIDER';
    case WIDGET_TYPE.TABLE:
      return 'WIDGETS_CONSTANTS.TABLE';
    case WIDGET_TYPE.TEXT:
    case WIDGET_TYPE.TITLE:
      return 'WIDGETS_CONSTANTS.TEXT';
    case WIDGET_TYPE.VIDEO:
      return 'WIDGETS_CONSTANTS.VIDEO';
    case WIDGET_TYPE.CARD:
      return 'WIDGETS_CONSTANTS.CARD';
    case WIDGET_TYPE.YOUTUBE:
      return 'WIDGETS_CONSTANTS.YOUTUBE';
    case WIDGET_TYPE.VIMEO:
      return 'WIDGETS_CONSTANTS.VIMEO';

    default:
  }
};

export const emptyCSSObject: StyleSheet = {
  stylesheet: '',
  language: 'CSS',
  version: 3,
};
