import { generateRandomTextBlockKey } from '../../helpers/utils';

export const DEFAULT_SECTION_WIDTH = 800;
export const SECTION_WIDTH_S = 800;
export const SECTION_WIDTH_M = 1200;
export const SECTION_WIDTH_L = 2048;
export const SECTION_WIDTH_XL = 3840;

export const DEFAULT_SECTION_HEIGHT = 120;
export const SECTION_MAX_HEIGHT = 2160;
export const SECTION_MID_HEIGHT = 1000;
export const SECTION_MIN_HEIGHT = 10;

export const SECTION_CONTENT_SPACING_NONE = 0;
export const SECTION_CONTENT_SPACING_SMALL = 8;
export const SECTION_CONTENT_SPACING_MEDIUM = 16;
export const SECTION_CONTENT_SPACING_LARGE = 32;
export const SECTION_CONTENT_SPACING_XLARGE = 64;
export const DEFAULT_SECTION_CONTENT_SPACING = SECTION_CONTENT_SPACING_MEDIUM;

export const SECTION_PADDING_NONE = 0;
export const SECTION_PADDING_SMALL = 8;
export const SECTION_PADDING_MEDIUM = 16;
export const SECTION_PADDING_LARGE = 64;
export const DEFAULT_SECTION_HORIZONTAL_PADDING = SECTION_PADDING_NONE;
export const DEFAULT_SECTION_VERTICAL_PADDING = SECTION_PADDING_MEDIUM;

export const SECTION_COLUMN_GAP_NONE = 0;
export const SECTION_COLUMN_GAP_SMALL = 20;
export const SECTION_COLUMN_GAP_MEDIUM = 40;
export const SECTION_COLUMN_GAP_LARGE = 80;
export const DEFAULT_SECTION_COLUMN_GAP = SECTION_COLUMN_GAP_MEDIUM;

export const DEFAULT_SECTION_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Lorem ipsum dolor sit amet","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"left"}}],"entityMap":{}}`;
export const DEFAULT_TEXT_HERO_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Hero Banner Title","type":"header-three","depth":0,"inlineStyleRanges":[{"offset":0,"length":17,"style":"color-#000000"},{"offset":0,"length":17,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}},{"key":"${generateRandomTextBlockKey()}","text":"Subtitle","type":"header-five","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"color-#000000"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const DEFAULT_TEXT_TEXT_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const TITLE_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Welcome to Your DAM Portal","type":"header-three","depth":0,"inlineStyleRanges":[{"offset":0,"length":26,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const TEXT_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Your homepage is your gateway to all things your brand. With our pre-built templates and widgets, you can easily customize your homepage.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}},{"key":"${generateRandomTextBlockKey()}","text":"Use it to showcase your brand, organize key assets, and easily navigate to everything you need.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const REDIRECT_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Redirection to pre-filtered views in the asset bank","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const CARDS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Add custom thumbnails and links to get users where they need quickly.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const COLLECTIONS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Collections","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const COLLECTION_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"You can embed a Collection from the DAM to publish a group of assets. If you embed the Collection, it becomes public and accessible to anyone with access.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const QUICK_LINKS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Quick Links","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const EXPRESS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Share Files Via Bynder Express","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"left"}}],"entityMap":{}}`;
export const EXPRESS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"You can use Bynder Express instead of a third-party app (WeTransfer or Hightail) to share files (up to 40 GB) via email or link.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"${generateRandomTextBlockKey()}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"${generateRandomTextBlockKey()}","text":"The files you share in Bynder Express will not be uploaded or shown in the Asset Bank and will not require a login to download. The transferred files count toward your contracted DAM storage; however, once the link expires, it will no longer count toward your storage space.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":38,"length":8,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const VIDEOS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Add videos to make your homepage more dynamic","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const VIDEOS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Embed videos from Youtube and Vimeo or directly from your DAM.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const NAVIGATION_LINKS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Navigation links","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const LINK_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Link 1","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":6,"key":0}],"data":{}}],"entityMap":{"0":{"type":"LINK","mutability":"MUTABLE","data":{"url":"http://about:blank","targetOption":"_blank"}}}}`;
export const BUTTON_STYLE_HOMEPAGE_TEMPLATE =
  'background-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const DEFAULT_BUTTON_STYLE_HERO_TEMPLATE =
  'background-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nborder-color:#000000;\nbackground-color:#FFFFFF;\ncolor:#000000;\n}\n';
export const DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE =
  'background-color:#FFFFFF;\ncolor:#000000;\nborder-color:#000000;\n&:hover{\nborder-color:#000000;\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const BUTTON_TEXT_HOMEPAGE_TEMPLATE = "Let's get started";
export const EMBED_COLLECTION_BUTTON_TEXT =
  'Find out how to embed a collection';
export const DEFAULT_BUTTON_TEXT_HERO_TEMPLATE = 'Call to Action';

export enum SectionTemplatesEnum {
  WHITE_TEMPLATE = 'WHITE_TEMPLATE',
  HERO_TEMPLATE = 'HERO_TEMPLATE',
  TWO_CARD_TEMPLATE = 'TWO_CARD_TEMPLATE',
  FOUR_CARD_TEMPLATE = 'FOUR_CARD_TEMPLATE',
  SIX_CARD_TEMPLATE = 'SIX_CARD_TEMPLATE',
  FOOTER_WITH_IMAGE_TEMPLATE = 'FOOTER_WITH_IMAGE_TEMPLATE',
  TEXT_TEMPLATE = 'TEXT_TEMPLATE',
  TWO_COLUMN_FEATURED_TEMPLATE = 'TWO_COLUMN_FEATURED_TEMPLATE',
  HERO_WITH_IMAGE_TEMPLATE = 'HERO_WITH_IMAGE_TEMPLATE',
  LANDING_PAGE_TEMPLATE = 'LANDING_PAGE_TEMPLATE',
}
