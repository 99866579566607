import { create } from 'zustand';
import { InspectorSetting } from '../constants/guides';
import { SectionTemplatesEnum } from '../constants/sections';

interface InspectorStore {
  isInspectorOpen: boolean;
  activeSectionComputedHeight: number;
  activeInspectorSetting: InspectorSetting;
  activeSectionTemplate: SectionTemplatesEnum;
  sectionPosition: number,
  widgetSettings: {
    widgetId?: string;
    sectionId?: string;
    columnIndex?: number;
    widgetIndex?: number;
  };
  setSectionPosition: (sectionPosition: number) => void;
  setSectionTemplate: (activeSectionTemplate: SectionTemplatesEnum) => void;
  setIsInspectorOpen: (isInspectorOpen: boolean) => void;
  setActiveSectionComputedHeight: (activeSectionComputedHeight: number) => void;
  setActiveInspectorSetting: (activeInspectorSetting: InspectorSetting) => void;
  setWidgetSettings: (widgetSettings: {
    widgetId?: string;
    sectionId?: string;
    columnIndex?: number;
    widgetIndex?: number;
  }) => void;
}

export const useInspectorStore = create<InspectorStore>(set => ({
  isInspectorOpen: false,
  activeInspectorSetting: null,
  widgetSettings: null,
  activeSectionComputedHeight: null,
  activeSectionTemplate: SectionTemplatesEnum.WHITE_TEMPLATE,
  sectionPosition: null,
  setSectionPosition: sectionPosition => set(() => ({ sectionPosition })),
  setSectionTemplate: activeSectionTemplate => set(() => ({ activeSectionTemplate })),
  setIsInspectorOpen: isInspectorOpen => set(() => ({ isInspectorOpen })),
  setActiveSectionComputedHeight: activeSectionComputedHeight =>
    set(() => ({ activeSectionComputedHeight })),
  setActiveInspectorSetting: activeInspectorSetting =>
    set(() => ({ activeInspectorSetting })),
  setWidgetSettings: widgetSettings =>
    set(state => ({
      widgetSettings: {
        ...state.widgetSettings,
        ...widgetSettings,
      },
    })),
}));
