import {
  BaseCommand,
  Cover,
  EntityVisibility,
  Identity,
  Permission,
  Chapter,
} from '.';

export enum GuideType {
  SINGLE_PAGE = 'SINGLE_PAGE',
  MULTI_PAGE = 'MULTI_PAGE',
}

export interface Guide {
  id: string;
  title: string;
  visibility: EntityVisibility;
  createdById?: string;
  creationTime?: string;
  lastModifiedTime?: string;
  version: number;
  isFeatured: boolean;
  isFresh?: boolean;
  editors?: Identity[];
  chapters?: Chapter[];
  cover?: Cover;
  groupId: string;
  ownerId: string;
  canEdit: boolean;
  viewAccessLists: AccessLists;
  editAccessLists: AccessLists;
  visibleToAllPortalUsers?: boolean;
  themesetId?: string;
  permissions: Permission[];
  firstPageId: string;
  type: GuideType;
}

export interface GuideLimit {
  aggregateId: string;
  spaceAvailableKilobytes: string;
  spaceAvailablePercentage: string;
  spaceUsedKilobytes: string;
  spaceUsedPercentage: string;
}

export interface AccessLists {
  users: Identity[];
  groups: Identity[];
  profiles: Identity[];
}

export interface GuidesResponse {
  allGuides: Guide[];
  featuredGuides: Guide[];
}

export interface NormalizedGuidesResponse {
  allGuides: GuideMap;
  featuredGuidesIds: string[];
}

export interface GuideMap {
  [key: string]: Guide;
}

export interface SearchGuidesResponse {
  allGuides: Guide[];
  featuredGuides: Guide[];
}

export type EditGuideTitleCommand = BaseCommand & {
  guideTitle: string;
};

export type SetGuideCoverCommand = BaseCommand & {
  guideCover: Cover;
};

export type CreateGuideWithTemplateCommand = BaseCommand & {
  guideTitle: string;
  chapterId: string;
  chapterTitle: string;
  pageId: string;
  pageTitle: string;
  sectionId: string;
};

export type CreateGuideWithTemplateInsideGroupCommand = BaseCommand & {
  guideTitle: string;
  chapterId: string;
  chapterTitle: string;
  pageId: string;
  pageTitle: string;
  sectionId: string;
  groupId: string;
  groupVersion: number;
};

export type GuideCommands = EditGuideTitleCommand | SetGuideCoverCommand;

export enum OrderBy {
  CREATION_TIME = 'creationTime',
  LAST_MODIFIED_TIME = 'lastModifiedTime',
  TITLE = 'title',
}

export enum OrderType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface OverviewOrder {
  orderBy: OrderBy;
  orderType: OrderType;
  searchTerm?: string;
}
