import { Color } from './color';

export enum TypographyHeading {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  NORMAL = 'Normal',
  H6 = 'H6',
}
export interface Typography {
  id?: string;
  label: string;
  heading?: TypographyHeading;
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: string;
  fontColor?: Color;
  creationTime?: string;
  lastModifiedTime?: string;
}

export type TypographyLabelMap = {
  [heading in TypographyHeading]: string;
};
