import React from 'react';
import {
  Button,
  Form,
  Textarea,
  Input,
  Flex,
  ModalBase,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import {
  GROUP_TITLE_MAX_LENGTH,
  GROUP_DESCRIPTION_MAX_LENGTH,
} from '../../constants/groups';
import { track } from '../../helpers/analytics';
import { GroupAttributes } from '../overview/OverviewHeader';
import PrimaryButton from '../shared/PrimaryButton';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';

export interface CreateGroupModalProps {
  onCancel: () => void;
  onSubmit?: (groupProps: GroupAttributes) => void;
  initialTitleValue?: string;
  initialDescriptionValue?: string;
}

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({
  onCancel,
  onSubmit,
  initialDescriptionValue,
  initialTitleValue,
}) => {
  const { translate } = useTranslate();
  const [title, setTitle] = React.useState(initialTitleValue || '');
  const [description, setDescription] = React.useState(
    initialDescriptionValue || '',
  );
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    if (
      title.length > GROUP_TITLE_MAX_LENGTH ||
      title === '' ||
      (title === initialTitleValue && description === initialDescriptionValue)
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [title, description, initialTitleValue, initialDescriptionValue]);

  const handleSubmit = () => {
    track('Group Created');
    onSubmit({
      groupTitle: title,
      groupDescription: description,
    });
    onCancel();
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header
        title={translate('CREATE_GROUP_MODAL.DEFAULT_HEADER_TEXT')}
      />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="entityTitle">
            <Translate id="CREATE_GROUP_MODAL.INPUT_LABEL" />
          </Form.Label>
          <Form.InputRow>
            <Input
              value={title}
              onChange={setTitle}
              data-testid="groupTitle"
              aria-label="group-title"
              placeholder={translate('CREATE_GROUP_MODAL.INPUT_PLACEHOLDER')}
              maxLength={GROUP_TITLE_MAX_LENGTH}
              iconRight={
                <MaxCharacterCounter
                  value={title}
                  maxValue={GROUP_TITLE_MAX_LENGTH}
                />
              }
            />
          </Form.InputRow>
        </Form.Group>
        <Form.Group>
          <Form.Label
            htmlFor="groupDescription"
            secondaryText={translate(
              'CREATE_GROUP_MODAL.TEXTAREA_ADDITIONAL_LABEL',
            )}
          >
            {translate('CREATE_GROUP_MODAL.TEXTAREA_LABEL')}
          </Form.Label>
          <Textarea
            id="groupDescription"
            placeholder={translate('CREATE_GROUP_MODAL.TEXTAREA_PLACEHOLDER')}
            value={description}
            maxLength={GROUP_DESCRIPTION_MAX_LENGTH}
            onChange={e => setDescription(e)}
            aria-describedby="groupDescription"
          />
          <Flex justifyContent="flex-end">
            <Form.HelperText id="groupDescription">
              <MaxCharacterCounter
                value={description}
                maxValue={GROUP_DESCRIPTION_MAX_LENGTH}
              />
            </Form.HelperText>
          </Flex>
        </Form.Group>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            disabled={isButtonDisabled}
            onClick={() => (onSubmit ? handleSubmit() : onCancel())}
          >
            <Translate id="CREATE_GROUP_MODAL.DEFAULT_BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="CREATE_GROUP_MODAL.CANCEL_BUTTON_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};
