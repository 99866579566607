import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { findIndex, flatten } from 'lodash';
import {
  fetchGuideByIdApi,
  fetchGuideLimitById,
  fetchGuides,
  fetchPageByIdApi,
} from '../helpers/api/guidelinesApi';
import { useUserType, UserType } from '../helpers/hooks';
import { Guide, GuideType, Section } from '../types';
import { queryClient } from '../constants/app';
import { useGuideStore } from '../stores/guideStore';
import { useInspectorStore } from '../stores/inspectorStore';
import { useOverviewStore } from '../stores/overviewStore';

export const OVERVIEW_KEYS = {
  OVERVIEW_GUIDES: 'OVERVIEW_GUIDES',
  OVERVIEW_GROUPS: 'OVERVIEW_GROUPS',
};

export const GUIDE_KEYS = {
  GUIDE_BY_ID: 'GUIDES',
  GUIDE_LIMIT: 'GUIDE_LIMIT',
  PAGE_BY_ID: 'PAGES',
};

export const useGetOverviewGuides = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GUIDES, orderBy, orderType],
    () => fetchGuides({ orderBy, orderType }, isPublic),
    {
      enabled: !!orderBy && !!orderType,
      select: data => ({
        allGuides: data?.allGuides?.filter(
          guide => guide.type === GuideType.MULTI_PAGE,
        ),
        featuredGuides: data?.featuredGuides?.filter(
          guide => guide.type === GuideType.MULTI_PAGE,
        ),
      }),
    },
  );
};

export const useGetOverviewHomepages = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GUIDES, orderBy, orderType],
    () => fetchGuides({ orderBy, orderType }, isPublic),
    {
      enabled: !!orderBy && !!orderType,
      select: data =>
        data?.allGuides?.filter(guide => guide.type === GuideType.SINGLE_PAGE),
    },
  );
};

export const useOverviewDocumentById = (guideId: string): Guide => {
  const { data: guides } = useGetOverviewGuides();
  const { data: homepages } = useGetOverviewHomepages();

  let guide = guides?.allGuides?.find(guide => guide.id === guideId);
  if (!guide) {
    guide = homepages?.find(guide => guide.id === guideId);
  }
  return guide;
};

export const useFetchGuideById = (guideId: string) => {
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery<Guide, AxiosError>(
    [GUIDE_KEYS.GUIDE_BY_ID, guideId],
    () => fetchGuideByIdApi(guideId, isPublic),
    {
      enabled: !!guideId && !!userType,
      retry: false,
    },
  );
};

export const usePrefetchPage = (pageId: string) => {
  const { userType } = useUserType();
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  if (pageId) {
    return queryClient.prefetchQuery(
      [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, pageId],
      () => fetchPageByIdApi(activeGuideId, pageId, isPublic),
    );
  }
};

export const useFetchPageById = (guideId: string, pageId: string) => {
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    () => fetchPageByIdApi(guideId, pageId, isPublic),
    {
      enabled: !!guideId && !!pageId && !!userType,
      retry: false,
    },
  );
};

export const useChapterById = (guideId: string, chapterId: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  return guide?.chapters.find(chapter => chapter.id === chapterId);
};

export const useGetFirstViewablePageId = (guideId: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  const pages = flatten(guide?.chapters?.map(chapter => chapter.pages));

  return pages?.find(page => page.canView)?.id;
};

export const useActivePage = () => {
  const { userType } = useUserType();
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activePageId = useGuideStore(s => s.activePageId);
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, activePageId],
    () => fetchPageByIdApi(activeGuideId, activePageId, isPublic),
    {
      enabled: !!activeGuideId && !!activePageId,
      retry: false,
    },
  );
};

export const useActiveWidget = () => {
  const widgetSettings = useInspectorStore(s => s.widgetSettings);
  const { data: page } = useActivePage();
  const sectionIndex = findIndex(
    page?.sections,
    section => section.id === widgetSettings?.sectionId,
  );

  return page?.sections[sectionIndex]?.columns[
    widgetSettings?.columnIndex
  ]?.widgets?.find(w => w.id === widgetSettings?.widgetId);
};

export const useActiveChapter = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activeChapterId = useGuideStore(s => s.activeChapterId);
  return useChapterById(activeGuideId, activeChapterId);
};

export const useGetPageChapterId = (guideId: string, pageId?: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  return guide?.chapters?.find(chapter =>
    chapter.pages?.find(page => page.id === pageId),
  )?.id;
};

export const useGetPageByChapterId = (
  guideId: string,
  chapterId: string,
  pageId: string,
) => {
  const { data: guide } = useFetchGuideById(guideId);

  return guide?.chapters
    ?.find(chapter => chapter.id === chapterId)
    ?.pages?.find(page => page.id === pageId);
};

export const useFetchGuideSizeLimit = (guideId: string) => {
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [GUIDE_KEYS.GUIDE_LIMIT, guideId],
    () => fetchGuideLimitById(guideId),
    { enabled: !!guideId && !isPublic },
  );
};

export const useActiveGuide = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery<Guide, AxiosError>(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId],
    () => fetchGuideByIdApi(activeGuideId, isPublic),
    {
      enabled: !!activeGuideId && !!userType,
    },
  );
};

export const useActiveSection = () => {
  const activeSectionId = useGuideStore(s => s.activeSectionId);
  const { data: page } = useActivePage();

  const returnObject = {
    activeSection: {} as Section,
    previousSectionId: null as string,
    nextSectionId: null as string,
    sectionIndex: null as number,
    sectionCount: null as number,
  };

  return page?.sections.reduce((acc, section, i, pageSections) => {
    if (section.id === activeSectionId) {
      acc.activeSection = section;
      acc.sectionIndex = i;
      acc.sectionCount = pageSections.length;
      if (acc.sectionIndex > 0) {
        acc.previousSectionId = pageSections[acc.sectionIndex - 1].id;
      }
      if (acc.sectionIndex < acc.sectionCount - 1) {
        acc.nextSectionId = pageSections[acc.sectionIndex + 1].id;
      }
    }

    return acc;
  }, returnObject);
};
